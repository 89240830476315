import React, { useState, useEffect, useContext } from 'react';
import { apiRequest } from '../api';
import { AuthContext } from '../context/AuthContext';
import './Tasks.css';
import API_BASE_URL from '../apiConfig';


const TasksPage = ({ balance, setBalance }) => {
    const { user } = useContext(AuthContext);
    const [tasks, setTasks] = useState([]);
    const [isClaiming, setIsClaiming] = useState(false);
    const [claimSuccessPopup, setClaimSuccessPopup] = useState(false);
    
 
    useEffect(() => {
        const fetchTasks = async () => {

            try {
                const response = await apiRequest(`${API_BASE_URL}/api/tasks` , {
                    method: 'GET',
                    credentials: 'include',
                });

                const data = await response.json();
                if (response.ok) {
                    setTasks(data.tasks);
                 
                    
                } else {
                    console.error('Failed to fetch tasks:', data.message);
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        };

        fetchTasks();
    }, []);

    const fetchTasksAfterClime = async () => {

        try {
            const response = await apiRequest(`${API_BASE_URL}/api/tasks` , {
                method: 'GET',
                credentials: 'include',
            });

            const data = await response.json();
            if (response.ok) {
                setTasks(data.tasks);
             
                
            } else {
                console.error('Failed to fetch tasks:', data.message);
            }
        } catch (error) {
            console.error('Error fetching tasks:', error);
        }
    };


    const handleClaimTaskReward = async (taskId) => {
        
        setIsClaiming(true);

        try {
            const response = await apiRequest(`${API_BASE_URL}/api/claim-task-reward` , {
                method: 'POST',
                credentials: 'include',
                headers: {

                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ taskId }),
            });

            const data = await response.json();
            if (response.ok) {
                triggerConfettiAnimation();
                setBalance(data.newBalance);
                setTasks((prevTasks) => prevTasks.map(task => 
                    task.id === taskId ? { ...task, completed: true } : task
                ));
                setClaimSuccessPopup({
                    isVisible: true,
                    title: 'Reward Claimed!',
                    message: `You've successfully claimed $${data.reward} for completing the task!`,
                });
            } else {
                console.error('Failed to claim task reward:', data.message);
            }
        } catch (error) {
            console.error('Error claiming task reward:', error);
        } finally {
            setIsClaiming(false);
        }
    };


    const triggerConfettiAnimation = () => {
        const confettiContainer = document.createElement('div');
        confettiContainer.className = 'confetti-container';
    
        for (let i = 0; i < 250; i++) {
            const confetti = document.createElement('div');
            confetti.className = 'confetti-piece';
            
            confetti.style.left = '50%';
            confetti.style.top = '50%';
            confetti.style.backgroundColor = `hsl(${Math.random() * 360}, 100%, 50%)`;
            confetti.style.transform = `rotate(${Math.random() * 360}deg)`;

            const randomX = (Math.random() - 0.5) * window.innerWidth * 2;
            const randomY = (Math.random() - 0.5) * window.innerHeight * 2;
            const duration = Math.random() * 1.4 + 0.8;

            confetti.style.animation = `explode ${duration}s ease-out forwards`;
            confetti.style.setProperty('--randomX', `${randomX}px`);
            confetti.style.setProperty('--randomY', `${randomY}px`);

            confettiContainer.appendChild(confetti);
        }

        document.body.appendChild(confettiContainer);

        setTimeout(() => {
            document.body.removeChild(confettiContainer);
        }, 4000);
    };

    const handleClosePopup = async () => {
    setClaimSuccessPopup({ isVisible: false });
    await fetchTasksAfterClime(); // Refetch tasks after closing the popup
};


    return (
        <div className="tasks-page-container">
            <h2 className="tasks-page-title">Tasks</h2>
            <p className="tasks-page-description">Complete tasks to earn rewards. Claim your rewards and boost your balance!</p>
            
        
            {tasks.length > 0 && (
    <h3 className="tasks-available-title">
        {tasks.length === 1 ? '1 task available' : `${tasks.length} tasks available`}
    </h3>
)}


            <div className="tasks-list">
                {tasks.length > 0 ? (
                    tasks.map((task) => (
                        <div key={task._id} className="task-item">
                            <div className="task-info">
                                <h4 className="task-name">{task.name}</h4>
                                
                                {task.condition === 'invite_20_friends' && (
                                    <p className="task-status">{`${user.invitedFriends.length}/20 friends, +$${task.reward}`}</p>
                                )}
                                {task.condition === 'win_50_games' && (
                                    <p className="task-status">{`${user.wins}/50 games, +$${task.reward}`}</p>
                                )}
                            </div>

                            {task.completed ? (
    <button className="task-claim-button completed" disabled>
        <img src="/icons/checkmark-icon.svg" alt="Checkmark" className="checkmark-icon" />
        <span className="completed-label"></span> 
    </button>
) : (
    <button 
        className={`task-claim-button ${!task.conditionMet ? 'disabled' : ''}`} 
        onClick={() => handleClaimTaskReward(task._id)} 
        disabled={!task.conditionMet}
    >
        Claim
    </button>
)}

                        </div>
                    ))
                ) : (
                    <p className="no-tasks-message">No tasks available at the moment. Check back later!</p>
                )}
            </div>

            {claimSuccessPopup.isVisible && (
    <div
        className="claim-reward-popup-overlay"
        onClick={(e) => {
            if (!e.target.closest('.claim-reward-popup')) {
                handleClosePopup();
            }
        }}
    >
        <div className="claim-reward-popup">
            <h3 className="popup-title">{claimSuccessPopup.title}</h3>
            <p className="popup-message">{claimSuccessPopup.message}</p>
            <button
                className="claim-popup-close-button"
                onClick={handleClosePopup} // Use the close handler
            >
                OK
            </button>
        </div>
    </div>
)}
        </div>
    );
};


export default TasksPage;
