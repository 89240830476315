import React, { useEffect, useState,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './MorePage.css'; 
import { AuthContext } from '../context/AuthContext';


const MorePage = () => {
    const navigate = useNavigate();
    const [showUserAgreement, setShowUserAgreement] = useState(false);

    useEffect(() => {
        const handlePopState = (event) => {
            event.preventDefault();
            navigate('/game'); 
        };

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate]);

    const { logout } = useContext(AuthContext);

    const handleGamesHistoryClick = () => {
        navigate('/played-games-history');
    };

    const handleTransactionHistoryClick = () => {
        navigate('/transaction-history');
    };

    const handleAffiliateProgramClick = () => {
        navigate('/affiliate-program');
    };

    const handleUserAgreementClick = () => {
        setShowUserAgreement(true); 
    };

    const handleChatWithSupportClick = () => {
        window.open('https://t.me/your-support-bot', '_blank');
    };

    const handleCloseUserAgreement = () => {
        setShowUserAgreement(false); 
    };

   
    return (
        <div className="more-page-container">
            <h2 className="more-page-title">More</h2>
            <ul className="more-options-list">
                <li className="more-option-item" onClick={handleGamesHistoryClick}>
                    <span className="more-option-text">Played Games</span>
                    <img className="more-option-arrow" src="/icons/arrow-icon.svg" alt="Arrow Icon" />
                </li>
                <li className="more-option-item" onClick={handleTransactionHistoryClick}>
                    <span className="more-option-text">Transaction History</span>
                    <img className="more-option-arrow" src="/icons/arrow-icon.svg" alt="Arrow Icon" />
                </li>
                <li className="more-option-item" onClick={handleAffiliateProgramClick}>
                    <span className="more-option-text">Affiliate Program</span>
                    <img className="more-option-arrow" src="/icons/arrow-icon.svg" alt="Arrow Icon" />
                </li>
                <li className="more-option-item" onClick={handleUserAgreementClick}>
                    <span className="more-option-text">User Agreement</span>
                    <img className="more-option-arrow" src="/icons/arrow-icon.svg" alt="Arrow Icon" />
                </li>
                <li className="more-option-item" onClick={handleChatWithSupportClick}>
                    <span className="more-option-text">Chat with Support</span>
                    <img className="more-option-arrow" src="/icons/arrow-icon.svg" alt="Arrow Icon" />
                </li>
                <li className="more-option-item" onClick={logout}>
                    <span className="more-option-text">Logout</span>
                    <img className="more-option-arrow" src="/icons/logout-icon.svg" alt="Logout Icon" />
                </li>
            </ul>

            {/* User Agreement Popup */}
            {showUserAgreement && (
                <div className="user-agreement-popup-overlay-custom" onClick={handleCloseUserAgreement}>
                    <div className="user-agreement-popup-custom" onClick={(e) => e.stopPropagation()}>
                        <div className="user-agreement-header-custom">
                            <h3 className="user-agreement-title-custom">User Agreement</h3>
                            <button className="user-agreement-close-btn-custom" onClick={handleCloseUserAgreement}>
                                &times;
                            </button>
                        </div>
                        <hr className="user-agreement-separator-custom" />
                        <div className="user-agreement-body-custom">
                            <p>
                                <strong>1. Introduction</strong> 
                                <br />
                                <br />
                                Welcome to ppbet.com! This User Agreement governs
                                your use of our online gaming services. By registering and using ppbet.com, you agree
                                to the terms set forth in this agreement.
                            </p>
                            <p>
                                <strong>2. Eligibility</strong> 
                                <br />
                                <br />
                                You must be at least 18 years old or the legal age of
                                majority in your jurisdiction to use our services. It is your responsibility to ensure
                                compliance with local laws.
                            </p>
                            <p>
                                <strong>3. Digital Assets Only</strong> 
                                <br />
                                <br />
                                ppbet.com operates exclusively with digital
                                assets. Users can play using digital currencies, but we do not facilitate any
                                transactions involving fiat currency.
                            </p>
                            <p>
                                <strong>4. Account Registration</strong>
                                <br />
                                <br />
                                a. You are responsible for providing accurate information during registration.
                                <br />
                                b. You must safeguard your account credentials and take full responsibility for
                                actions taken under your account.
                                <br />
                                c. The creation of multiple accounts is prohibited. Violations may result in the
                                suspension of all associated accounts.
                            </p>
                            <p>
                                <strong>5. Fair Play</strong>
                                <br />
                                <br />
                                a. The use of bots or third-party software to manipulate gameplay is strictly
                                prohibited.
                                <br />
                                b. Cheating or collusion with other players will result in penalties, including
                                potential forfeiture of assets.
                            </p>
                            <p>
                                <strong>6. Deposits and Withdrawals</strong> 
                                <br />
                                <br />
                                All transactions are made using digital
                                assets. Withdrawals are processed to your digital wallet after review.
                            </p>
                            <p>
                                <strong>7. Limitation of Liability</strong> 
                                <br />
                                <br />
                                ppbet.com is not liable for any damages
                                arising from the use or inability to use the service.
                            </p>
                            <p>
                                <strong>8. Dispute Resolution</strong> 
                                <br />
                                <br />
                                In the event of disputes, efforts will be made
                                to resolve them through mediation. If necessary, legal action will follow.
                            </p>
                            <p>
                                <strong>9. Termination</strong> 
                                <br />
                                <br />
                                We reserve the right to suspend or terminate user
                                accounts in cases of suspected fraudulent or illegal activity.
                            </p>
                            <p>
                                <strong>10. Changes to Agreement</strong> 
                                <br />
                                <br />
                                ppbet.com reserves the right to update
                                this agreement. Changes will be posted on the site, and continued use implies acceptance.
                            </p>
                            <p>
                                <strong>11. Governing Law</strong> 
                                <br />
                                <br />
                                This agreement is governed by the laws of the
                                jurisdiction in which ppbet.com operates.
                            </p>
                            <p>
                                <strong>12. Contact</strong> 
                                <br />
                                <br />
                                If you have any questions about this agreement, please
                                contact us at support@ppbet.com.
                            </p>
                        </div>

                        
                    </div>
                </div>
            )}
        </div>
    );
};

export default MorePage;
