import React, { useEffect, useState, useContext, useRef, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { apiRequest } from '../api';
import './blotpage-styles.css';
import './hand-styles.css';
import './PlayerLabel.css';
import './GamePoints.css';
import './LastPlayedHand.css';
import gsap from 'gsap';
import API_BASE_URL from '../apiConfig';
import audioManager from './AudioManager';



const BlotPage = ({ setBalance }) => {
    const [game, setGame] = useState(null);
    const messageRef = useRef('');
    const [opponents, setOpponents] = useState([]);
    const [popupMessage, setPopupMessage] = useState('');
    const [selectedCard, setSelectedCard] = useState(null);
    const navigate = useNavigate();
    const { user,socket } = useContext(AuthContext);
    const [ShowCancelPopup, setShowCancelPopup] = useState(false);
    const [showEndGamePopup, setShowEndGamePopup] = useState(false);
    const [showTrumpDecisionPopup, setShowTrumpDecisionPopup] = useState(false);
    const [showTrumpSelectionPopup, setShowTrumpSelectionPopup] = useState(false);
    const [showRoundCanceledPopup, setShowRoundCanceledPopup] = useState(false);
    const [showTrumpFinalDecisionPopup, setShowTrumpFinalDecisionPopup] = useState(false);
    const [showRoundResultsPopup, setShowRoundResultsPopup] = useState(false);    
    const [showAnnounceCancelPopup, setShowAnnounceCancelPopup] = useState(true); 
    const localPlayedCards = useRef([]);
    const animations = useRef([]);
    const [initialDeck, setInitialDeck] = useState([]);   // State for the initial deck of 24 cards
    const deckInitialized = useRef(false);
    const [isMoveInProgress, setisMoveInProgress] = useState(false); // Track if any animation is running
    const playAreaRef = useRef(null);
    const playerCardsRef = useRef({});
    const initialDeckCardsRef = useRef([]);
    const allCardsAllowedRef = useRef({});
    const opponent = opponents.find(opponent => opponent._id !== user._id);
    const currentlyAnimatingCardId = useRef(null);
    const currentlyAnimatingOpponentCardId = useRef(null);
    const playerCombinationDecision = useRef('announce');
    const [renderedPlayedCards, setRenderedPlayedCards] = useState([]);
    const [combinationPopupFlags, setCombinationPopupFlags] = useState({});
    const [blotPopupFlags, setBlotPopupFlags] = useState({});
    const [showCombination, setShowCombination] = useState({});
 
  
  

    useEffect(() => {
        // Apply overflow: hidden when the BlotPage is mounted
        document.body.style.overflow = 'hidden';
    
        // Cleanup function to reset overflow back to 'auto' when the component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);



    

    const getPlayArea = () => {
        const playArea = document.querySelector('.playarea');
        if (playArea) {
            playAreaRef.current = playArea;
            console.log('Play area selected successfully.');
        } else {
            console.warn('Play area not found.');
        }
    };

    useEffect(() => {
        if (game?.status === 'in-progress') {
            if (!playAreaRef.current) {
                getPlayArea(); // Attempt to select the play area when the game status changes
            }
        }
    }, [game]);
    
   
    
    useEffect(() => {
        if (Object.keys(playerCardsRef.current).length >= 8) {
        return;
        }
    
        const playerCards = document.querySelectorAll('.player-section .card');
    
        playerCardsRef.current = {}; // Resetting the current ref
    
        playerCards.forEach(card => {
            const cardId = card.getAttribute('data-id');
            playerCardsRef.current[cardId] = card;
        });
    
        console.log('Cached player cards:', playerCardsRef.current); // Log cached cards for debugging
    
    }, [game?.hands]);
    

    useEffect(() => {
        if (!game || !game.phase) {
            return;
        }
    
        // Use requestAnimationFrame to optimize UI updates
        requestAnimationFrame(() => {
            if (isMoveInProgress || !['card-played', 'trick-completed', 'in-progress'].includes(game.phase)) {
                disablePlayerCards();
            } else {
                enablePlayerCards();
            }
        });
    }, [game, isMoveInProgress]);
    
    const disablePlayerCards = () => {
        // Use cached references to player cards for optimization
        Object.values(playerCardsRef.current).forEach(card => {
            card.classList.add('disabled');
        });
    };
    
    const enablePlayerCards = () => {
        // Use cached references to player cards for optimization
        Object.values(playerCardsRef.current).forEach(card => {
            card.classList.remove('disabled');
        });
    };
    
  
    useEffect(() => {
        if (!game || !['initial-deal', 'deal-initial-cards', 'face-up-card-taken', 'face-up-card-skiped', 'deal-remaining-cards', 'trump-decision', 'trump-selection', 'trump-final-selection'].includes(game.phase)) {
            return; 
        }
    
        if (initialDeck.length === 0) {
            console.log('Initializing initial deck');
            initializeInitialDeck(); // Attempt to initialize the deck
        }
    
        cacheInitialDeck(game);
        
    }, [game]);
    
    const cacheInitialDeck = (game) => {
        // Check if we've already cached the deck
        if (game.phase !== 'deal-initial-cards' && initialDeckCardsRef.current.length > 0) {
            console.log('Deck already cached, skipping caching.');
            return; // Exit if already cached
        }

        
        const initialDeckCards = document.querySelectorAll('.initial-deck-card');
    
        // Only cache if elements are found
        if (initialDeckCards.length > 0) {
            initialDeckCardsRef.current = Array.from(initialDeckCards);
            console.log('Deck elements cached successfully.');
        } else {
            // Use requestAnimationFrame to check again
            requestAnimationFrame(cacheInitialDeck);
        }
    };

    useEffect(() => {
        const opponentId = opponents.find(opponent => opponent._id !== user._id)?._id;
        
        if (!game || !game.hands || !game.hands[user._id] || !game.hands[opponentId] || game.hands[user._id].length < 7) {
            return; // Return early if any of these are undefined or do not meet the condition
        }
        
        // Check if the game update indicates a card was played and it's the first move
        if (game?.playedCards?.length === 1 || game?.playedCards?.length === 2) {
            const lastPlayedCard = game.playedCards[game.playedCards.length - 1];
    
            if (lastPlayedCard) {
                
                const cardPlayerId = lastPlayedCard.player;
    
                // If the last played card is by the current player, show the popup for the player
                if (user._id === cardPlayerId && game.hands[user._id].length >= 7 ) {
                    showCombinationPopup(user._id);  // Show player's combination popup
                }
    
                // If the last played card is by the opponent, show the popup for the opponent
                if (user._id !== cardPlayerId && game.hands[opponentId].length >= 7 ) {
                    showCombinationPopup(opponentId);  // Show opponent's combination popup
                }
            }
        }
    }, [game?.playedCards]);

    useEffect(() => {
        if (!game){return;};

        if (game.blotAnnounced) {
            showBlotPopup();  // Show the Blot announcement popup
        }
    }, [game?.blotAnnounced]);
    
    
    
    useEffect(() => {
        if (!game){return;};

        if (game.showCombinations) {
            showWinerCombinations();  
        }
    }, [game?.showCombinations]);
     
        



       
        
        useEffect(() => {
        if (!user) {
                navigate('/login');
                return;
            }
      
            socket.on('game-update', handleGameUpdate);
                                           
                return () => {
                console.log('Cleaning up socket listeners in BlotPage');
                socket.off('game-update', handleGameUpdate);
                };
        }, [user,socket]);
    
         
    

    

    
    const resettimerAnimation = (playerId) => {
        console.log('Resetting animation for player:', playerId);
    
        // Selecting the player element with the correct data-player-id
        const playerElement = document.querySelector(`.player-section[data-player-id="${playerId}"], .opponent-section[data-player-id="${playerId}"]`);
        
        if (playerElement) {
            console.log('Player element found:', playerElement);
    
            playerElement.classList.remove('waiting-for-move');
            console.log('Removed "waiting-for-move" class from player element.');
    
            // Force a reflow, flushing the CSS changes
            void playerElement.offsetWidth;
            console.log('Forced reflow on player element.');
    
            // Re-add the class to trigger the animation
            playerElement.classList.add('waiting-for-move');
            console.log('Re-added "waiting-for-move" class to player element.');
        } else {
            console.error('Player element not found for player ID:', playerId);
        }
    };
    
    
    

    const handleGameUpdate = async (data) => {

        if (!data.game) {
        console.error('No game found in the update data.');
        // navigate('/game');
        return;
    }
        console.log('BlotPage - Game update received:', data);
        
    
        // Normalize opponents array safely
    const normalizedOpponents = data.opponents.map(opponent => ({
        ...opponent,
        _id: opponent._id || opponent.id,
        nickname: opponent.nickname,
        country: opponent.country || ''
    }));

    
  
    setGame(data.game);
    setOpponents(normalizedOpponents);
    const currentTurn = data.game?.turn;
    console.log('Current turn:', currentTurn);



    if (currentTurn) {
        // Check if the phase is "in-progress" (the first move after game starts)
        if (data.game.phase === 'in-progress') {
            if (currentTurn === user._id) {
                console.log('Restarting animation for user:', user._id);
                resettimerAnimation(user._id);
            } else {
                const opponent = normalizedOpponents.find(opponent => opponent._id === currentTurn);
                if (opponent && opponent._id === currentTurn) {
                    console.log('Restarting animation for opponent:', opponent._id);
                    resettimerAnimation(opponent._id);
                } else {
                    console.error('Opponent not found for current turn:', currentTurn, 'Opponents:', opponents);
                }
            }
        }
        
        // Handle "trump-decision" phase
        else if (data.game.phase === 'trump-decision') {
            if (currentTurn === user._id) {
                console.log('Restarting animation for user during trump decision:', user._id);
                resettimerAnimation(user._id);
            } else {
                const opponent = normalizedOpponents.find(opponent => opponent._id === currentTurn);
                if (opponent && opponent._id === currentTurn) {
                    console.log('Restarting animation for opponent during trump decision:', opponent._id);
                    resettimerAnimation(opponent._id);
                } else {
                    console.error('Opponent not found for current turn:', currentTurn, 'Opponents:', opponents);
                }
            }
        }
    
        // Handle "trick-completed" phase
        else if (data.game.phase === 'trick-completed') {
            if (currentTurn === user._id) {
                console.log('Restarting animation for user:', user._id);
                resettimerAnimation(user._id);
            } else {
                const opponent = normalizedOpponents.find(opponent => opponent._id === currentTurn);
                if (opponent && opponent._id === currentTurn) {
                    console.log('Restarting animation for opponent:', opponent._id);
                    resettimerAnimation(opponent._id);
                } else {
                    console.error('Opponent not found for current turn:', currentTurn, 'Opponents:', opponents);
                }
            }
        } else {
            console.log('Skipping animation restart due to phase:', data.game.phase);
        }
    } else {
        console.error('Turn is undefined in the game update:', data);
    }
    
    

        if (data.game.phase === 'game-canceled' || data.game.status === 'canceled' ) {
        setShowCancelPopup(true); // Show the cancellation popup
        setShowTrumpDecisionPopup(false);
        setShowTrumpSelectionPopup(false);
        setShowTrumpFinalDecisionPopup(false);
        setInitialDeck([]);
        initialDeckCardsRef.current = []; // Clear the ref as well
        
        
        // Wait 4 seconds before navigating to the home page
        setTimeout(() => {
            setShowCancelPopup(false); // Hide the popup
            navigate('/game'); // Navigate to the home page
        }, 4000);

        return; // No need to handle other phases if the game is canceled
         }

        
         if (data.game.status === 'in-progress' && data.game.phase === 'deal-initial-cards') {            
            playerCardsRef.current = {};
            localPlayedCards.current = [];
            await new Promise(resolve => setTimeout(resolve, 1000));
            const deckCards = initialDeckCardsRef.current;
            if (deckCards.length > 0) {
                // If the deck is available, call the animation function
                animateCardDealing(data.game, normalizedOpponents);
            } else {
                // Query the DOM to select elements if the cache is not available
                const initialDeckCards = document.querySelectorAll('.initial-deck-card');
                if (initialDeckCards.length > 0) {
                    initialDeckCardsRef.current = Array.from(initialDeckCards);
                    console.log('Deck elements cached from DOM.');
                    // Call the animation function with the newly cached deck cards
                    animateCardDealing(data.game, normalizedOpponents);
                } else {
                    console.warn('No deck cards found in the DOM.');
                }
            }

        }

        
        if (data.game.status === 'in-progress' && data.game.phase === 'face-up-card-taken'){
            
            
            animateFaceUpCardToPlayer(data.game, normalizedOpponents);
           
        }

        if (data.game.status === 'in-progress' && data.game.phase === 'face-up-card-skeeped'){
            
            animateFaceUpCardToRightSide(data.game);
           
        }

        if (data.game.status === 'in-progress' && data.game.phase === 'deal-remaining-cards') {

             if (hasanimateRemainingCardDealingAnimationRun.current) {
                return;
            }
            const deckCards = initialDeckCardsRef.current;
            if (deckCards.length > 0) {
                // If the deck is available, call the animation function
                animateRemainingCardDealing(data.game, normalizedOpponents);
            } 
            
        }

        

        
    
        if (data.game.phase === 'card-played' || data.game.phase === 'trick-in-progress') { 
             
            handleCardPlayedPhase(data.game); // Reuse the same function for both phases
            removeDeck(); // Remove decks to ensure no visual clutter
        }

        
    
    
        if (data.game.phase === 'trick-completed') {
            handleTrickCompletion(data.game);
        }

       
        if (data.game.phase === 'round-completed') {
            handleRoundCompletion(data.game);
            setShowRoundResultsPopup(true);
        }

        if (data.game.phase === 'game-completed') {
            handleTrickCompletion(data.game);
            handleGameEnded(data.game)
        }

        if (data.game.phase === 'round-canceled') {
            setPopupMessage('Round canceled. Starting a new round...');
            handleRoundCanceled();
        }

        
    
        handlePopups(data.game);
    };

    // Async functions for deck initialization
    const initializeInitialDeck = async () => {
    if (deckInitialized.current) {
        return;
    }
    
    const deckCards = [];
    const totalCards = 24;
    for (let i = 0; i < totalCards; i++) {
        deckCards.push({
            id: i,
            zIndex: 100 - i,
            transform: `translateY(${(totalCards - i - 1) * -0.5}px) translateX(${(totalCards - i - 1) * -0.5}px)`,
        });
    }
    setInitialDeck(deckCards); // Set the initial deck
    deckInitialized.current = true;
    
};

    


const handleCardPlayedPhase = async (game, isInitialLoad = false) => {



// If this is the initial load after a refresh, populate localPlayedCards immediately
if (isInitialLoad) {
    localPlayedCards.current = game.playedCards.map((playedCard, index) => ({
        ...playedCard,
        positionClass: index === 0 ? 'first-card-position' : 'second-card-position'
    }));
 } else {
    const playedCard = game.playedCards[game.playedCards.length - 1];
    const cardElement = playerCardsRef.current[playedCard._id];
    
    if (cardElement) {      
        if (playedCard.autoPlayed && !document.hidden) {
            console.log('Card was auto-played, handling animation only.');
            try {
             
               audioManager.playSound('opponentCardPlay');

                currentlyAnimatingCardId.current = playedCard._id;

                await moveToPlayArea(game, cardElement, playedCard, false, null,null,true );  

            } catch (error) {
                console.error('Error during auto-play card move animation:', error);
            }
        }
    }

    
                 const isOpponent = playedCard.player !== user._id;
                 if (isOpponent) {      
          
                
            
                    // Create the opponent card element manually (it won't be rendered in the final place)
         const cardSrc = `/cards/${playedCard.value}_of_${playedCard.suit}.png`;
     
         // Create an img element for the opponent card
         const opponentCardElement = document.createElement('img');
         opponentCardElement.src = cardSrc;
         opponentCardElement.className = 'card in-play animating disable-hover';
         opponentCardElement.style.position = 'absolute';
         opponentCardElement.style.left = `50%`;
         opponentCardElement.style.transform = `translateX(-50%)`;
         opponentCardElement.style.top = `-50px`;
           
         // Append to the body for positioning, but this element won't stay there permanently
         document.body.appendChild(opponentCardElement);
     
         // Get the bounding rectangle of the newly created element
         const rect = opponentCardElement.getBoundingClientRect();
         opponentCardElement.remove();
     
                  if (opponentCardElement) {
     
     
                    try {
                       
                       audioManager.playSound('opponentCardPlay');
                        currentlyAnimatingOpponentCardId.current = playedCard._id;             
                        await moveToPlayArea(game, opponentCardElement, playedCard, true, cardSrc, rect,);
        
                    } catch (error) {
                        console.error('Error during auto-play card move animation:', error);
                    } 
                 
                 
                  setTimeout(() => {
                    localPlayedCards.current = game.playedCards.map((playedCard, index) => ({
                        ...playedCard,
                        positionClass: index === 0 ? 'first-card-position' : 'second-card-position'
                    }));
                }, 200);
                 
             } else {
                 console.error('Opponent card element not found');
             
                 setTimeout(() => {
                    localPlayedCards.current = game.playedCards.map((playedCard, index) => ({
                        ...playedCard,
                        positionClass: index === 0 ? 'first-card-position' : 'second-card-position'
                    }));
                }, 100);
             }
     
         }else {
        // Handle your own player's card
        setTimeout(() => {
            localPlayedCards.current = game.playedCards.map((playedCard, index) => ({
                ...playedCard,
                positionClass: index === 0 ? 'first-card-position' : 'second-card-position'
            }));
        }, 100);
    }
              
 }
 };
 
    const handleRoundCompletion = async (game) => {
       const results = game?.roundResults;
    
       if (results?.winnerId === user._id )
       {
        audioManager.playSound('win');
       }
       
       if (results?.winnerId != user._id)
        {
        audioManager.playSound('lose');
       }
        await resetAnimationFlags();
        await handleTrickCompletion(game); 
        vibrate(150);
        
        };
    

    const resetAnimationFlags = async () => {

        
        currentlyAnimatingFaceUpCard.current = false;
        hasanimateRemainingCardDealingAnimationRun.current = false;
        deckInitialized.current = false;
        currentlyAnimatingCardId.current = null; 
        currentlyAnimatingOpponentCardId.current = null;
        playerCardsRef.current = {};
        setCombinationPopupFlags({});
        playerCombinationDecision.current = 'announce';
        setShowAnnounceCancelPopup(true);
 
        // Add any other flags or states that need resetting
    };
    
    
    

    

    const vibrate = (pattern) => {
        if ('vibrate' in navigator) {
            navigator.vibrate(pattern);
        }
    };
 
    

    const handleGameEnded = async (game) => {
        console.log('BlotPage - Game ended:', game);
        
        const { winners = [], losers = [], winnerBalances = {}, loserBalances = {} } = game;
    
        if (user && user._id) {
    
            let message = '';
            if (winners.includes(user._id)) {
                message = 'You have won!';
                audioManager.playSound('win');
                setBalance(winnerBalances[user._id]);  
            } else if (losers.includes(user._id)) {
                message = 'You lost the game';
                audioManager.playSound('lose');
                setBalance(loserBalances[user._id]);  
            }

            setShowRoundResultsPopup(true);
            await new Promise(resolve => setTimeout(resolve, 3000));
            setShowRoundResultsPopup(false);
    
       
            setPopupMessage(message);
            setShowEndGamePopup(true);
            setShowTrumpDecisionPopup(false);
            setShowTrumpSelectionPopup(false);
            setShowTrumpFinalDecisionPopup(false);
    
            await resetAnimationFlags();  
            
            
            vibrate([400,50,100]);
            
            await new Promise(resolve => setTimeout(resolve, 6000)); 
    
           
            setShowEndGamePopup(false);
            
            navigate('/game');
            
           
            setGame(null);
        } else {
            console.error('User context is missing or user ID is undefined');
        }
    };
    


    const getAllowedCards = (playerHand, firstCardPlayed, trumpSuit) => {
        if (!firstCardPlayed) {
          
            return playerHand;
        }
    
        const firstCardSuit = firstCardPlayed.suit;
        const hasSuitInHand = playerHand.some(card => card.suit === firstCardSuit);
        const hasTrumpInHand = playerHand.some(card => card.suit === trumpSuit);
    
       
        if (firstCardSuit === trumpSuit) {
        
            const higherTrumpCards = playerHand.filter(card => card.suit === trumpSuit && getCardValue(card, trumpSuit) > getCardValue(firstCardPlayed, trumpSuit));
            if (higherTrumpCards.length > 0) {
                return higherTrumpCards;
            }
       
            if (hasTrumpInHand) {
                return playerHand.filter(card => card.suit === trumpSuit);
            }
       
            return playerHand;
        }
    
      
        if (hasSuitInHand) {
         
            return playerHand.filter(card => card.suit === firstCardSuit);
        }
    
        if (hasTrumpInHand) {
      
            return playerHand.filter(card => card.suit === trumpSuit);
        }
    
   
        return playerHand;
    };
    
    const getCardValue = (card, trumpSuit) => {
        if (trumpSuit === 'ANXOZ') {
           
            return {
                '9': 0, 'J': 2, 'Q': 3, 'K': 4, '10': 10, 'A': 19
            }[card.value];
        }
    
        if (card.suit === trumpSuit) {
          
            return {
                '9': 14, 'J': 20, 'Q': 3, 'K': 4, '10': 10, 'A': 11
            }[card.value];
        }
    
       
        return {
            '9': 0, 'J': 2, 'Q': 3, 'K': 4, '10': 10, 'A': 11
        }[card.value];
    };

    const showCombinationPopup = (userId) => {
        setCombinationPopupFlags((prevState) => ({
            ...prevState,
            [userId]: true, 
        }));
    
        
        setTimeout(() => {
            setCombinationPopupFlags((prevState) => ({
                ...prevState,
                [userId]: false,
            }));
        }, 3200);
    };
    

    const showBlotPopup = () => {
      
        setBlotPopupFlags({
            player: true,   
            opponent: true  
        });
    
       
        setTimeout(() => {
            setBlotPopupFlags({
                player: false,   
                opponent: false 
            });
        }, 1800);
    };


    const showWinerCombinations = () => {

        setShowCombination({
            player: true,   
            opponent: true  
        });
    
   
        setTimeout(() => {
            setShowCombination({
                player: false,   
                opponent: false 
            });
        }, 2800);
    };
    
    
    const isCardClicked = useRef(false);

    const handleCardClick = async (game,card) => {      
          
        if (isMoveInProgress) {
            console.log('Move already in progress, ignoring click');
            return;
        }    

        if (isCardClicked.current) {
            console.log("Move already in progress, ignoring click.");
            return;
        }

         
         



        if (game.turn !== user._id) {
            messageRef.current = 'It is not your turn';
            return;
        }
        
 
        
        const allowedCards = getAllowedCards(game.hands[user._id], game.playedCards[0], game.trumpSuit);
        const isAllowedCard = allowedCards.some(
        (allowedCard) => allowedCard.suit === card.suit && allowedCard.value === card.value
        );

        if (!isAllowedCard) {
        console.log('This card is not allowed to be played');
        return; 
        }        

    
       audioManager.playSound('playerCardPlay');

        allCardsAllowedRef.current[user._id] = true;      
    
        const cardElement = playerCardsRef.current[card._id];
        console.log('Card Element Found:', cardElement);

        if (cardElement) { 
            isCardClicked.current = true;          
         
            currentlyAnimatingCardId.current = card._id; 
                        
            try {
          
             
                const updatedHands = { ...game.hands };
                updatedHands[user._id] = updatedHands[user._id].filter(c => c._id !== card._id);
                setGame({ ...game, hands: updatedHands });                
                await moveToPlayArea(game, cardElement, card);  
                await handleMove(card);  
                isCardClicked.current = false;
                            
                               
            } catch (error) {
                console.error('Error during card move:', error);               
            } finally {              
                allCardsAllowedRef.current[user._id] = false;
             
                isCardClicked.current = false;
            }
        } else {
            console.error('Card element not found for animation');
        }
    };

    const handleMove = async (card) => {

    try {

    
        const response = await apiRequest(`${API_BASE_URL}/api/blot/make-move`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ gameId: game._id, card, combinationDecision: playerCombinationDecision.current })
        });

        const data = await response.json();
        if (response.ok) {
            messageRef.current = 'Move made successfully';
            setSelectedCard(null); 
            setGame(data.game);
            console.log('Game state after move:', data.game);
        } else {
            messageRef.current = 'Failed to make move';
        }
    } catch (err) {
        messageRef.current = 'An error occurred';
    }
};




  

const moveToPlayArea = async (game, cardElement, playedCard, isOpponentCard = false, cardSrc = null, initialRect = null, isBotMove = false) => {
    
    

    
    return new Promise(resolve => {
        let clonedCard;

        const playArea = playAreaRef.current;

        // Make sure playArea exists before proceeding
        if (!playArea) {
        console.error('Play area not found');
        
        if (clonedCard && document.body.contains(clonedCard)) {
            clonedCard.remove();
            clonedCard.classList.remove('animating', 'disable-hover'); // Clean up CSS classes
        }
        resolve();
        return;
        };      
              
        let isSecondCard;
        
       
        if (isBotMove) {
            isSecondCard = (game.moveCounter % 2 === 1); 
        } else if (!isOpponentCard) {
            isSecondCard = game.moveCounter % 2 === 0; 
        } else {
            isSecondCard = (game.moveCounter - 1) % 2 === 0; 
        }


        

        if (document.hidden) {
            if (clonedCard && document.body.contains(clonedCard)) {
            clonedCard.remove();
            clonedCard.classList.remove('animating', 'disable-hover'); // Clean up CSS classes
            }
            if (!isSecondCard) {
                setisMoveInProgress(false);  
            }
            resolve();
            return;
        }      
        
       
        if (isOpponentCard && cardSrc && initialRect) {
            // If it's an opponent card, use the src and rect provided
            clonedCard = cardElement.cloneNode(true);
            clonedCard.src = cardSrc; // Set the front face for the opponent
            clonedCard.className = `card in-play ${isSecondCard ? 'second-card-position' : 'first-card-position'} animating disable-hover`;
            clonedCard.style.position = 'absolute';
            clonedCard.style.left = `${initialRect.left}px`;
            clonedCard.style.top = `${initialRect.top}px`;
            clonedCard.style.width = `${initialRect.width}px`;
            clonedCard.style.height = `${initialRect.height}px`;
            clonedCard.style.zIndex = '1';
            document.body.appendChild(clonedCard);
        }  else {

            if (isBotMove) {
                // If it's a bot move and the card is no longer in hand, create the card manually
                clonedCard = document.createElement('img');
                clonedCard.src = `/cards/${playedCard.value}_of_${playedCard.suit}.png`;
                clonedCard.className = `card in-play ${isSecondCard ? 'second-card-position' : 'first-card-position'} animating disable-hover`;
        
                // Set initial position based on where the player's hand is (adjust as needed)
                const playerHandArea = document.querySelector('.player-section .player-hand'); // Assuming there's a .player-hand-area element
                if (playerHandArea) {
                    const playerHandRect = playerHandArea.getBoundingClientRect();
                    clonedCard.style.position = 'absolute';
                    clonedCard.style.left = `50%`;
                    clonedCard.style.top = `${playerHandRect.top}px`;
                    clonedCard.style.transform = 'translateX(-50%)';
                    clonedCard.style.width = '80px'; // Set the card size
                    clonedCard.style.height = '120px';
                    clonedCard.style.zIndex = '1';
                } else {
                    console.error('Player hand area not found');
                }
                
                document.body.appendChild(clonedCard);
            } else {
                // For the player's card, handle it normally
                clonedCard = cardElement.cloneNode(true);
                clonedCard.className = `card in-play ${isSecondCard ? 'second-card-position' : 'first-card-position'} animating disable-hover`;
                
                // Attempt to get the bounding rectangle
                const rect = cardElement.getBoundingClientRect();
                
                // Check if the card is still in the DOM and has valid position data
                const isValidPosition = rect && rect.left !== 0 && rect.top !== 0;
            
                // Set position based on whether we have a valid starting point
                if (isValidPosition) {
                    clonedCard.style.left = `${rect.left}px`;
                    clonedCard.style.top = `${rect.top}px`;
                } else {
                    // Safeguard: if position is invalid, set it to the bottom center of the screen
                    clonedCard.style.left = '50%';
                    clonedCard.style.top = '90%';
                    clonedCard.style.transform = 'translateX(-50%)';
                }
                
                clonedCard.style.position = 'absolute';
                clonedCard.style.zIndex = '1';
                document.body.appendChild(clonedCard);
            }
            
        }      

        setisMoveInProgress(true);

     

        // Start the animation using GSAP
        const playAreaRect = playArea.getBoundingClientRect();
        const rect = clonedCard.getBoundingClientRect(); // Get the card element's current position

        // Calculate the offsets for moving the card to the play area using transform for better performance
        const xOffset = playAreaRect.left - rect.left + (playAreaRect.width / 2 - rect.width / 2);
        const yOffset = playAreaRect.top - rect.top + (playAreaRect.height / 2 - rect.height / 2);

        // Adjust the position for the second card
        const xOffsetAdjusted = isSecondCard ? xOffset + 20 : xOffset;
        const yOffsetAdjusted = isSecondCard ? yOffset + 10 : yOffset;
        const rotationAngle = isSecondCard ? 15 : 0;

        gsap.to(clonedCard, {
            duration: 0.38, // Duration of the animation
            x: xOffsetAdjusted,
            y: yOffsetAdjusted,
            rotation: rotationAngle,
            ease: 'power2.inOut',
            onComplete: () => {
                if (!isSecondCard) {
                    setisMoveInProgress(false);  
                }
            
                if (!localPlayedCards.current.some(card => card._id === playedCard._id)) {
                    localPlayedCards.current = [
                        ...localPlayedCards.current,
                        { 
                            ...playedCard, 
                            positionClass: !isSecondCard ? 'first-card-position' : 'second-card-position' 
                        }
                    ];
                
                }

                resolve(); // Resolve the promise after the animation completes  
                             
                setTimeout(() => {
                    if (clonedCard && document.body.contains(clonedCard)) {
                        setRenderedPlayedCards([...localPlayedCards.current]);
                        clonedCard.remove();                    
                        clonedCard.classList.remove('animating', 'disable-hover'); // Clean up CSS classes
                    }
                }, 0);                
                              
            }
        });
    });
};


const handleTrickCompletion = async (game) => {
    setisMoveInProgress(false); // Allow interaction during animateToWinnerSide animation for better UX
    
    const lastPlayedCards = game.lastPlayedCards;

    if (lastPlayedCards.length > 1) {
        // Prepare the cards for animation
        const playCards = lastPlayedCards.map(card => ({
            ...card.card,
            playerId: card.playerId // Include playerId if needed for animation
        }));

        try {

            // Start the animation
            await animateCardsToWinner(playCards, game);           
        } catch (error) {
            console.error('Error during trick completion:', error);
        }
    }
};


const animateCardsToWinner = async (playCards, game) => {
    try {        
        const trickWinnerId = game.trickWinner;
        
        if (document.hidden) {
            console.log('Tab is inactive, skipping animation');
            return;
        }

        const targetPosition = trickWinnerId === user._id
            ? { x: 0 * window.innerWidth, y: window.innerHeight }  // Move to player's area
            : { x: 0 * window.innerWidth, y: -1 * window.innerHeight }; // Move to opponent's area

        const animations = playCards.map(card => {
            const cardElement = document.querySelector(`.playarea .card[data-id="${card._id}"]`);
            if (cardElement) {
                return new Promise(resolve => {
                    gsap.to(cardElement, {
                        duration: 0.8,
                        x: targetPosition.x,
                        y: targetPosition.y,
                        onComplete: () => {
                            resolve(); // Resolve after animation completes
                        }
                    });
                });
            }
            return Promise.resolve(); // If card not found, resolve immediately
        });

        await Promise.all(animations); // Wait for all animations to complete

    } catch (error) {
        console.error('Error during card animations:', error);
    }finally {
   
      //  localPlayedCards.current = [];

    }
};






    const handlePopups = (game) => {
        if (game.phase === 'trump-decision') {
            setShowTrumpDecisionPopup(true);
            setShowTrumpSelectionPopup(false);
            setShowTrumpFinalDecisionPopup(false);
        } else if (game.phase === 'trump-selection') {
            setShowTrumpSelectionPopup(true);
            setShowTrumpDecisionPopup(false);
            setShowTrumpFinalDecisionPopup(false);
        } else if (game.phase === 'trump-final-selection') {
            setShowTrumpFinalDecisionPopup(true);
            setShowTrumpSelectionPopup(false);
            setShowTrumpDecisionPopup(false);
        } else if (game.phase === 'round-canceled') {
            setShowTrumpSelectionPopup(false);
            setShowTrumpDecisionPopup(false);
            setShowTrumpFinalDecisionPopup(false);
        } else {
            setShowTrumpDecisionPopup(false);
            setShowTrumpSelectionPopup(false);
            setShowTrumpFinalDecisionPopup(false);
        }
    };

    const handleRoundCanceled = () => {
        setShowRoundCanceledPopup(true);
        
        // resetAnimationFlags();
        currentlyAnimatingFaceUpCard.current = false;
        hasanimateRemainingCardDealingAnimationRun.current = false;
        deckInitialized.current = false;
        currentlyAnimatingCardId.current = null; 
        currentlyAnimatingOpponentCardId.current = null;
        playerCardsRef.current = {};
        setCombinationPopupFlags({});
        


        setTimeout(() => {
            setShowRoundCanceledPopup(false);
        }, 3000); // Show popup for 3 seconds
    };

    const handleTrumpDecision = async (takeCard) => {
        try {
            const response = await apiRequest(`${API_BASE_URL}/api/blot/trump-decision` , {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ gameId: game._id, takeCard }) // Ensure `takeCard` is sent
            });

            const data = await response.json();
            if (response.ok) {
                messageRef.current = 'Trump decision made successfully';
                setGame(data.game); // Update game state
            } else {
                messageRef.current = data.message || 'Failed to make trump decision';
            }
        } catch (err) {
            messageRef.current = 'An error occurred';
            console.error(err);
        }
    };

    const handleTrumpSelection = async (suit) => {
        try {
            const response = await apiRequest(`${API_BASE_URL}/api/blot/trump-selection` , {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ gameId: game._id, suit }) // Make sure suit is sent
            });

            const data = await response.json();
            if (response.ok) {
                messageRef.current = 'Trump selection made successfully';
                setGame(data.game); // Update game state

                // Keep popup visible but hide action buttons
                if (suit === 'pass') {
                    setShowTrumpSelectionPopup(true); // Keep the popup visible
                } else {
                    setShowTrumpSelectionPopup(false); // Hide the popup if a trump is selected
                }
            } else {
                messageRef.current = data.message || 'Failed to select trump';
            }
        } catch (err) {
            messageRef.current = 'An error occurred';
        }
    };

    const handleFinalTrumpDecision = async (withCard) => {
        try {
            const response = await apiRequest(`${API_BASE_URL}/api/blot/final-trump-decision` , {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ gameId: game._id, withCard })
            });

            const data = await response.json();
            if (response.ok) {
                setGame(data.game); // Update game state
                setPopupMessage('');
                setShowTrumpFinalDecisionPopup(false); // Hide the popup after the final decision
            } else {
                messageRef.current = data.message || 'Failed to make final trump decision';
            }
        } catch (err) {
            messageRef.current = 'An error occurred';
        }
    };



    const animateCardDealing = (game, normalizedOpponents) => {
        if (!game) return;
    
        const turnPlayerId = game.turn;
        const opponentId = normalizedOpponents.find(opponent => opponent._id !== turnPlayerId)?._id;
    
        if (document.hidden) {
            console.log('Tab is inactive, skipping card dealing animation');
            return;
        }
    
        if (!opponentId) {
            console.error('Opponent ID is undefined. Opponents:', normalizedOpponents);
            return;
        }
    
        const dealCardToPlayer = (playerId, index, delay) => {
            const deckCards = initialDeckCardsRef.current;
            const cardElement = deckCards[index];  // Select the top card
            if (!cardElement) return;

           
    
            // Determine the correct animation class
            const animationClass = playerId === user._id
                ? 'ppbet-dealCardToPlayer'
                : 'ppbet-dealCardToOpponent';
    
            // Add the animation class to trigger CSS animation
            cardElement.style.animation = `${animationClass} 0.9s ${delay}s ease-in-out forwards`;
    
            return new Promise((resolve) => {
                // Remove card after animation completes
                cardElement.addEventListener('animationend', () => {
                    if (document.body.contains(cardElement)) {
                        cardElement.remove();
                        initialDeckCardsRef.current = initialDeckCardsRef.current.filter(refCard => refCard !== cardElement);
                    }
                    resolve();
                });
            });
        };
    
        const dealCards = () => {
            
    
          audioManager.playSound('opponentDealCard');
            for (let i = 0; i < 5; i++) {
                dealCardToPlayer(turnPlayerId, i, i * 0.06);
            }
      
          setTimeout(() =>  audioManager.playSound('playerDealCard'), 1200);
            for (let i = 0; i < 5; i++) {
                dealCardToPlayer(opponentId, i + 5, i * 0.06 + 1.2);
            }
        };
    
        dealCards();
    };
    
    


const currentlyAnimatingFaceUpCard = useRef(false);
    

    
const animateFaceUpCardToPlayer = (game, normalizedOpponents) => {
   
    if (!game){
        return;
    }
    

    const playerId = game.faceUpCardTakenBy;
    const faceUpCardElement = document.querySelector('.face-up-card .Fcard'); 
    
    if (!faceUpCardElement || currentlyAnimatingFaceUpCard.current) return;




    if (document.hidden) {
        console.log('Document is hidden, skipping face-up card animation.');
        currentlyAnimatingFaceUpCard.current = false;
        return;  // Skip the animation if the document is hidden
    }

    audioManager.playSound('faceCardSound');
   

    currentlyAnimatingFaceUpCard.current = true;
// Clone the face-up card element for animation
const rect = faceUpCardElement.getBoundingClientRect();

// Clone the face-up card element for animation
const clonedCard = faceUpCardElement.cloneNode(true);
clonedCard.style.position = 'absolute';
clonedCard.style.width = '70px';
clonedCard.style.height = '110px';
// Use the original position of the face-up card
clonedCard.style.left = `${rect.left}px`;
clonedCard.style.top = `${rect.top}px`;
clonedCard.style.transform = 'rotate(90deg)';  // Keep the rotation
clonedCard.style.transformOrigin = 'center';
clonedCard.style.zIndex = '0';

document.body.appendChild(clonedCard);

const targetPosition = playerId === user._id
            ? { x: -0.5 * window.innerWidth, y: window.innerHeight }  // Move to player's area
            : { x: -0.5 * window.innerWidth, y: -1 * window.innerHeight }; // Move to opponent's area




const animation = gsap.timeline()
    .to(clonedCard, {
        duration: 0.2,
        rotate: '0deg',
        ease: 'power2.inOut',
    })
    .to(clonedCard, {
        duration: 0.96,
        x: targetPosition.x,
        y: targetPosition.y,
        ease: 'power2.inOut',
    })
    .eventCallback('onComplete', () => {
        currentlyAnimatingFaceUpCard.current = false;
        // Remove the cloned card after animation
        if (document.body.contains(clonedCard)) {
            clonedCard.remove();
        }

    });

animations.current.push(animation);
};

const animateFaceUpCardToRightSide = (game) => {
    const faceUpCardElement = document.querySelector('.face-up-card .Fcard');

    if (!faceUpCardElement || currentlyAnimatingFaceUpCard.current) return;

    if (document.hidden) {
        console.log('Document is hidden, skipping face-up card animation.');
        currentlyAnimatingFaceUpCard.current = false;
        return;  // Skip the animation if the document is hidden
    }

    currentlyAnimatingFaceUpCard.current = true;

    const rect = faceUpCardElement.getBoundingClientRect();

    // Clone the face-up card element for animation
    const clonedCard = faceUpCardElement.cloneNode(true);
    clonedCard.style.position = 'absolute';
    clonedCard.style.width = '70px';
    clonedCard.style.height = '110px';
    clonedCard.style.left = `${rect.left}px`;
    clonedCard.style.top = `${rect.top}px`;
    clonedCard.style.transform = 'rotate(90deg)';
    clonedCard.style.transformOrigin = 'center';
    clonedCard.style.zIndex = '0';

    document.body.appendChild(clonedCard);

    // Use CSS animation for rotating and moving off-screen to the right
    clonedCard.style.animation = `ppbet-rotateAndMoveOffScreen 1.2s ease-in-out forwards`;

    clonedCard.addEventListener('animationend', () => {
        currentlyAnimatingFaceUpCard.current = false;
        if (document.body.contains(clonedCard)) {
            clonedCard.remove(); // Remove the cloned card after animation
        }
    });
};





const hasanimateRemainingCardDealingAnimationRun = useRef(false); // Flag to ensure the animation runs only once

const animateRemainingCardDealing = async (game, normalizedOpponents) => {
    if (!game) return;

    try {
        const turnPlayerId = game.turn;
        const opponentId = normalizedOpponents.find(opponent => opponent._id !== turnPlayerId)?._id;

        if (!opponentId) {
            console.error('Opponent ID is undefined.');
            return;
        }

        if (hasanimateRemainingCardDealingAnimationRun.current) {
            console.log('Remaining card dealing animation already completed, skipping.');
            return;
        }

        if (document.hidden) {
            console.log('Tab is inactive, skipping remaining card dealing animation.');
            hasanimateRemainingCardDealingAnimationRun.current = true;
            setInitialDeck([]);
            initialDeckCardsRef.current = []; // Clear the ref as well
            return;
        }

        const deckCards = initialDeckCardsRef.current;

        hasanimateRemainingCardDealingAnimationRun.current = true;

        const dealCardToPlayer = (playerId, index, delay) => {
            const cardElement = deckCards[index];  // Select the card based on the index
            if (!cardElement) return;

            // Set target positions for the animation
            const animationClass = playerId === user._id
                ? 'ppbet-dealCardToPlayer'  // For the player
                : 'ppbet-dealCardToOpponent'; // For the opponent

            // Add the animation class to trigger CSS animation
            cardElement.style.animation = `${animationClass} 0.9s ${delay}s ease-in-out forwards`;

            return new Promise((resolve) => {
                cardElement.addEventListener('animationend', () => {
                    if (document.body.contains(cardElement)) {
                        cardElement.remove();
                        initialDeckCardsRef.current = initialDeckCardsRef.current.filter(refCard => refCard !== cardElement);
                    }
                    resolve(); // Resolve the promise once the animation is complete
                });
            });
        };

        const dealCards = () => {
            const animations = []; // Array to hold all card animations
        
            let dealerId = game.dealer.toString();
            let nonDealerId = game.userIds.find(id => id !== dealerId).toString();
        
            // Determine number of cards for each player
            let numCardsForNonDealer = game.faceUpCardTakenBy === nonDealerId ? 2 : 3;
            let numCardsForDealer = game.faceUpCardTakenBy === nonDealerId ? 3 : 2;
        
            // Deal cards to the non-dealer first

           audioManager.playSound('opponentDealRem_card');

            for (let i = 0; i < numCardsForNonDealer; i++) {
                animations.push(dealCardToPlayer(nonDealerId, i, i * 0.06)); // Collect promises for non-dealer
            }

           setTimeout(() => audioManager.playSound('playerDealRem_card'), 1200);
            // Deal cards to the dealer after
            for (let i = 0; i < numCardsForDealer; i++) {
                animations.push(dealCardToPlayer(dealerId, i + numCardsForNonDealer, i * 0.06 + 1.2)); // Collect promises for dealer
            }
        
            return Promise.all(animations); // Wait for all animations to complete simultaneously
        };
        

        await dealCards(); // Ensure all card animations are awaited

        const removeDeckWithAnimation = async () => {
            const remainingDeckCards = initialDeckCardsRef.current;

            if (remainingDeckCards.length === 0) {
                console.error('No remaining deck cards found to animate.');
                return;
            }

            await Promise.all(remainingDeckCards.map(card => {
                return new Promise((resolve) => {
                    // Add animation for removing the deck cards
                    card.style.animation = `ppbet-removeDeckCard 1.1s ease-in-out forwards`;

                    card.addEventListener('animationend', () => {
                        if (card.parentNode) {
                            card.remove();
                            initialDeckCardsRef.current = initialDeckCardsRef.current.filter(refCard => refCard !== card);
                        }
                        resolve(); // Resolve the promise once the animation is complete
                    });
                });
            }));

          
        };

        await removeDeckWithAnimation(); // Ensure this is awaited to complete

    } catch (error) {
        console.error('Error in animateRemainingCardDealing:', error);
    } finally {
        console.log('Executing finally block for animateRemainingCardDealing.');
        setInitialDeck([]);
        initialDeckCardsRef.current = []; // Clear the ref as well
    }
};




// Function to remove deck cards if the game is interrupted
const removeDeck = () => {
    setInitialDeck([]);
    initialDeckCardsRef.current = []; // Clear the ref as well
    console.log('Decks removed due to game interruption.');
};



const renderFaceUpCard = () => {
    if(!game || !game.phase){
        return
    };

    if (currentlyAnimatingFaceUpCard.current) return null; // Prevent rendering during animation

    if (
        game.phase === 'trump-decision' || 
        game.phase === 'trump-selection' || 
        game.phase === 'trump-final-selection' || 
        game.phase === 'face-up-card-taken' ||
        game.phase === 'face-up-card-skeeped'
    ){
    return (
        <div className="face-up-card">
            <div className="Fcard">
                <img
                    src={`/cards/${game.faceUpCardCopy.value}_of_${game.faceUpCardCopy.suit}.png`}
                    alt={`${game.faceUpCardCopy.value} of ${game.faceUpCardCopy.suit}`}
                />
            </div>
        </div>
    );
};
};

    
    
const renderPlayerHand = () => {

    if(!game)return;

    const userId = user._id;
    const playerHand = game.hands[userId] || [];
    const initialCards = game.initialCards[userId] || [];
    const faceUpCard = game.faceUpCardCopy || null;

    // Hide hands during the initial deal animation
    if (game.phase === 'deal-initial-cards') {
        return null;
    }

    // Determine which cards to hide during the remaining cards animation
    const hideRemainingCards = game.phase === 'deal-remaining-cards';
    const hideFaceUpCard = game.phase === 'face-up-card-taken' || currentlyAnimatingFaceUpCard.current;

    const filteredInitialHand = initialCards.filter(card => {
        return faceUpCard ? (card.suit !== faceUpCard.suit || card.value !== faceUpCard.value) : true;
    });

    let visibleHand;

    if (hideRemainingCards) {
        // Show initial cards (filtered) during the remaining card animation
        visibleHand = initialCards;
    } else if (hideFaceUpCard) {
        // Show filtered player hand (without face-up card) during the face-up card animation
        visibleHand = filteredInitialHand;
    } else {
        // Show full player hand (including face-up card) once both animations are done
        visibleHand = playerHand;
    }

    if (!playerHand || !Array.isArray(playerHand)) {
        return null; // Early return if playerHand is undefined or not an array
    }


    // Get allowed cards only when it's the player's turn and no card is being moved
    const allowedCards = (game.turn === userId && game.playedCards.length === 1 && !allCardsAllowedRef.current[userId])
        ? getAllowedCards(visibleHand, game.playedCards[0], game.trumpSuit)
        : visibleHand;

    // Determine winning combination cards
    const playerCombination = game?.winnerCombinations?.[userId];
    const winningCards = playerCombination
    ? ['fifty', 'tierce', 'hundred', 'fourX'].flatMap(type => 
        playerCombination[type]?.flatMap(combo => combo.cards) || []
      )
    : [];

    
    return (
        <div className={`player-hand ${
            visibleHand.length === 8 ? 'eight-cards' :
            visibleHand.length === 7 ? 'seven-cards' :
            visibleHand.length === 6 ? 'six-cards' :
            visibleHand.length === 5 ? 'five-cards' :
            visibleHand.length === 4 ? 'four-cards' :
            visibleHand.length === 3 ? 'three-cards' :
            visibleHand.length === 2 ? 'two-cards' :
            visibleHand.length === 1 ? 'one-cards' : ''
        }`}>
            {visibleHand.map((card,index) => {
                // Check if the current card is allowed
                const isAllowed = allowedCards.some(
                    allowedCard => allowedCard.suit === card.suit && allowedCard.value === card.value
                );

                // Check if the card is part of the player's winning combination
                const isWinningCard = winningCards.some(
                    (winningCard) => winningCard._id === card._id
                );

                // Apply animation class only when showCombination.player flag is true
                
                const isDealingCards = (visibleHand.length === 5 && game.trumpSuit === null) || visibleHand.length === 8;
                const entryAnimation = isDealingCards ? 'cardEntryAnimation' : '';


                const animationClass = (showCombination.player && isWinningCard && game?.moveCounter <= 5 ) 
                 ? 'card-animate-updown' 
                 : '';

                 // Set the rotation for the card dynamically using a style property
                const rotationDegrees = getCardRotation(visibleHand.length, index); // Implement a function to get the rotation
                const translateYValue = getCardTranslateY(visibleHand.length, index); // Function to get the translateY


                if (currentlyAnimatingCardId.current === card._id) return null;

                return (
                    <div
                        key={card._id}
                        className={`card ${selectedCard === card ? 'selected' : ''} ${isAllowed ? 'allowed-card' : 'not-allowed-card'} ${animationClass} ${entryAnimation}`}
                        style={{
                            '--card-rotation': `${rotationDegrees}deg`,
                            '--card-translateY': `${translateYValue}px`
                        }}
                        onTouchStart={isAllowed ? () => handleCardClick(game, card) : null} // Trigger as soon as touch starts
                        onClick={isAllowed ? () => handleCardClick(game, card) : null} // Keep onClick for desktop
                        data-id={card._id}
                    >
                        <img
                            src={`/cards/${card.value}_of_${card.suit}.png`}
                            alt={`${card.value} of ${card.suit}`}
                        />
                    </div>
                );
                
            })}
            {renderAnnounceCancelButtons()}
            
            {combinationPopupFlags[user._id] && renderCombinationPopupDialogForPlayer(user._id)}

            {game.combinations[user._id]?.blot && blotPopupFlags.player && 
localPlayedCards.current.some(playedCard => 
    playedCard.suit === game.trumpSuit && (playedCard.value === 'K' || playedCard.value === 'Q')
) && (
    <div className="player-popupComb">
        <div className="dialog-popup-inner player">
            <h3>Belote</h3>
        </div>
    </div>
)}

            


            {renderTrumpDecisionPopupButtons()}
            {renderTrumpDecisionPopupDialogForPlayer()}

            {renderTrumpSelectionPopupButtons()}
            {renderTrumpSelectionPopupDialogForPlayer()}

            {renderTrumpFinalSelectionPopupButtons()}
         {/*  {renderTrumpFinalSelectionPopupDialogForPlayer()}  */}

            
        </div>
    );
};
const getCardRotation = (handSize, cardIndex) => {
    const rotations = {
        8: [-9.6, -6.4, -3.2, -1.4, 1.4, 3.2, 6.4, 9.6],
        7: [-9.6, -6.4, -3.2, 0, 3.2, 6.4, 9.6],
        6: [-8, -4, -1.28, 1.28, 4, 8],
        5: [-8, -4, 0, 4, 8],
        4: [-6.4, -2.5, 2.5, 6.4],
        3: [-6.4, 0, 6.4],
        2: [-2.5, 2.5],
        1: [0]
    };
    return rotations[handSize][cardIndex];
};

const getCardTranslateY = (handSize, cardIndex) => {
    const translations = {
        8: [12.8, 6.4, 3.2, 0.5, 0, 2, 6.4, 12.8],
        7: [12.8, 6.4, 3.2, 2, 3.2, 6.4, 14],
        6: [10.2, 5.1, 2, 2, 5.1, 10.2],
        5: [10.2, 5.1, 2.5, 5.1, 10.2],
        4: [8.5, 4.25, 4.25, 8.5],
        3: [8.5, 4, 8.5],
        2: [6, 6],
        1: [0]
    };
    return translations[handSize][cardIndex];
};




const renderOpponentHand = () => {



    const opponentId = opponents.find(opponent => opponent._id !== user._id)?._id;
    if (!opponentId) return null;

    const opponentHand = game.hands[opponentId] || [];
    const initialCards = game.initialCards[opponentId] || [];
    const faceUpCard = game.faceUpCardCopy;


    // Hide hands during the initial deal animation
    if (game.phase === 'deal-initial-cards') {
        return null;
    }

    // Determine which cards to hide during the remaining cards animation
    const hideRemainingCards = game.phase === 'deal-remaining-cards' ;

    // Condition to hide the face-up card during its animation
    const hideFaceUpCard = game.phase === 'face-up-card-taken' || currentlyAnimatingFaceUpCard.current;

    // Filter the opponent's initial hand to exclude the face-up card if it's animating
    const filteredInitialHand = initialCards.filter(card => {
        return faceUpCard ? (card.suit !== faceUpCard.suit || card.value !== faceUpCard.value) : true;
    });

    let visibleHand;

    if (hideRemainingCards) {
        // Show the initial hand (filtered) during the remaining card animation
        visibleHand = initialCards;
    } else if (hideFaceUpCard) {
        // Show filtered hand (without the face-up card) during the face-up card animation
        visibleHand = filteredInitialHand;
    } else {
        // Show the full opponent's hand after both animations are done
        visibleHand = opponentHand;
    }

    // Exclude the animating card from rendering
    const animatingCardId = currentlyAnimatingOpponentCardId.current;
    if (animatingCardId) {
        visibleHand = visibleHand.filter(card => card._id !== animatingCardId);
    }
    
    const isDealingCards = (visibleHand.length === 5 && game.trumpSuit === null) || visibleHand.length === 8;
    const entryAnimation = isDealingCards ? 'cardEntryAnimation' : '';

    return (
        <div className="opponent-hand">
            {opponentHand.map((card, index) => (
                <div 
                    key={card._id} 
                    className={`card-back ${entryAnimation}`} // Apply the entry animation if needed
                    data-id={card._id}
                >
                    <img src="/cards/card_back.png" alt="Card Back" />
                </div>
            ))}
            
            {combinationPopupFlags[opponentId] && renderCombinationPopupDialogForOpponent(opponentId)}

            {!game.combinations[user._id]?.blot && blotPopupFlags.opponent && 
            localPlayedCards.current.some(playedCard => 
                playedCard.suit === game.trumpSuit && (playedCard.value === 'K' || playedCard.value === 'Q')
            ) && (
                <div className="opponent-popup">
                    <div className="dialog-popup-inner opponent">
                        <h3>Belote</h3>
                    </div>
                </div>
            )}

            {renderOpponentCombination()}
            {renderTrumpDecisionPopupDialogForOpponentSection()}
            {renderTrumpSelectionPopupDialogForOpponentSection()}
         {/*{renderTrumpFinalSelectionPopupDialogForOpponentSection()} */}
        </div>   
    );
};

// Function to render game points
const renderGamePoints = () => {
    if (!game || !game.totalGamePoints) return null;

    // Fetch current player and opponent's game points
    const playerGamePoints = game.totalGamePoints[user._id] || 0;
    const opponentGamePoints = game.totalGamePoints[opponent?._id] || 0;

    return (
        <div className="game-points-container">
            <div className="game-points-header">
                <span>Me</span>
                <span>You</span>
            </div>
            <div className="game-points-values">
                <span style={{ fontWeight: 'bold', fontSize: '13px' }}>{playerGamePoints}</span>
                /<span style={{ fontWeight: 'normal', fontSize: '11px' }}>101</span>
                <div className="game-points-divider"></div> {/* Divider */}
                <span style={{ fontWeight: 'bold', fontSize: '13px' }}>{opponentGamePoints}</span>
                /<span style={{ fontWeight: 'normal', fontSize: '11px' }}>101</span>
            </div>
        </div>
    );
    
    
};

const renderLastPlayedHand = () => {
    // If no game or playedCards data, return null
    if (!game) {
        return null;
    }

    const activePhases = [/*'in-progress',*/ 'card-played','trick-in-progress', 'trick-completed'];
    if (!activePhases.includes(game.phase)) {
        return null; // Do not render if not in an active phase
    }

    // Get the last set of played cards
    const lastPlayedCards = game.lastPlayedCards; // Use the lastPlayedCards field from the game object

    return (
        <>
            {lastPlayedCards.length === 2 && ( // Only render if there are 2 cards
                <div className="last-played-hand-container">
                    <div className="last-played-hand-cards">
                        {lastPlayedCards.map((playedCard) => (
                            <div
                                key={playedCard.card._id} // Use the card's unique ID
                                className="last-played-card"
                                style={{
                                    backgroundImage: `url(/cards/${playedCard.card.value}_of_${playedCard.card.suit}.png)`,
                                }}
                            />
                        ))}
                    </div>
                </div>
            )}
        </>
    );
    
   
};


const calculateWinnerGain = (betAmount) => {
    switch (betAmount) {
        case 5:
            return 9;
        case 10:
            return 18;
        case 20:
            return 36;
        default:
            return 0; // Default gain if bet amount is unknown
    }
};
    
    
    
const renderPopup = () => {

    if (!game || !game.turn) {
        // If the game object is null or game.turn is not defined, return null to avoid errors
        return null;
    }

    
    if (ShowCancelPopup) {
        return (
            <div className="popup">
                <div className="popup-inner">
                    <h3>Game Canceled</h3>
                    <p>The game has been canceled due to server maintenance. Your bet has been refunded.</p>
                    <button onClick={() => setShowCancelPopup(false)}>Close</button>
                </div>
            </div>
        );
    }

    if (showEndGamePopup && game?.status === 'completed') {
        const winnersArray = game.winners || [];
        const losersArray = game.losers || []; 
    
        if (winnersArray.length === 0 || losersArray.length === 0) {
            console.error("Winners or losers array is empty.");
            return null;
        }
    
        const winnerId = winnersArray[0];
        const loserId = losersArray[0];
        const winnerPoints = game.totalGamePoints[winnerId] || 0;
        const loserPoints = game.totalGamePoints[loserId] || 0;
        const isUserWinner = user._id === winnerId;
       
    
        return (
            <div className="ppbet-end-game-popup">
                <div className="ppbet-end-game-header">
                <h3 className={isUserWinner ? "ppbet-win" : "ppbet-lose"}>
        {isUserWinner ? (
            <>
                <span className="end-game-emoji">🏆</span> You Won the Game!
            </>
        ) : (
            <>
                <span className="end-game-emoji">😞</span> You Lost the Game
            </>
        )}
    </h3>
    <div className="ppbet-end-game-results-table">
    <div className="ppbet-end-game-results-row ppbet-end-game-header">
        <div>Game Result</div>
    </div>
    <div className="ppbet-end-game-results-row ppbet-end-game-score">
        <div className={user._id === winnerId ? "ppbet-end-game-winner-score" : "ppbet-end-game-loser-score"}>
            {user._id === winnerId ? winnerPoints : loserPoints}
        </div>
        <span className="ppbet-end-game-colon">:</span>
        <div className={user._id === winnerId ? "ppbet-end-game-loser-score" : "ppbet-end-game-winner-score"}>
            {user._id === winnerId ? loserPoints : winnerPoints}
        </div>
    </div>


    <div className="ppbet-endgame-results-table">
    <div className="ppbet-results-row ppbet-score-row">
        <div className="ppbet-results-left">
            <div className="ppbet-score-icon">
                <img src="/icons/score-icon.svg" alt="Score" />
            </div>
            <div className="ppbet-current-score">
                 {user.score}
            </div>
        </div>
        <div className="ppbet-results-right">
            <div className={`ppbet-score-result ${isUserWinner ? 'ppbet-score-positive' : 'ppbet-score-negative'}`}>
                {isUserWinner ? "+15" : "-10"}
            </div>
        </div>
    </div>

    <div className="ppbet-results-row ppbet-bet-row">
        <div className="ppbet-results-left">Bet Amount</div>
        <div className="ppbet-results-right">
            <span className="ppbet-dollar-signBET">$</span>
            <span className="ppbet-bet-amount">{game.betAmount}</span>
        </div>
    </div>

    {isUserWinner && game.betAmount > 0 && (
        <div className="ppbet-results-row ppbet-winning-row">
            <div className="ppbet-results-left">Winnings</div>
            <div className="ppbet-results-right">
                <span className="ppbet-dollar-sign">$</span> 
                <span className="ppbet-winning-amount">
                    {calculateWinnerGain(game.betAmount)}
                </span>
            </div>
        </div>
                    )}
                    </div>



                    </div>
                </div>
            </div>
        );
    }
    


    if (showRoundCanceledPopup) {
        return (
            <div className="popup">
                <div className="popup-inner">
                    <div style={{ margin: '0', padding: '0', color: '#000' }}>Round canceled. New round starting...</div>
                </div>
            </div>
        );
    }

    if (showRoundResultsPopup && (game.phase === 'round-completed' || game?.status === 'completed')) {
        
        const results = game.roundResults;
        const isUserTrumpSelector = user._id === game.trumpSuitSelectedBy?.toString();

        
        
        // 🎉  🙂
        return (
            <div className="ppbet-round-results-popup">
                <div className="ppbet-result-header">
                    {results.winnerId === user._id ? (
                        <div className="ppbet-round-status ppbet-win">
                            <h3>Round Won! <span className="end-round-emoji">😊</span></h3>
                        </div>
                    ) : (
                        <div className="ppbet-round-status ppbet-lose">
                            <h3>Round Lost <span className="end-round-emoji">😞</span></h3>
                        </div>
                    )}
                </div>
                <div className="ppbet-results-table">
                    <div className="ppbet-results-row ppbet-header">
                        <div></div>
                        <div>Me</div>
                        <div>You</div>
                    </div>
                    {results.details.map((detail, index) => (
    <div className="ppbet-results-row" key={index}>
        <div>{detail.name}</div>
        <div>
            {detail.name === "Taken"
                ? isUserTrumpSelector
                    ? detail.mePoints
                    : detail.opponentPoints
                : isUserTrumpSelector
                    ? detail.mePoints !== "-" 
                        ? detail.mePoints / 10 
                        : detail.mePoints
                    : detail.opponentPoints !== "-"
                        ? detail.opponentPoints / 10
                        : detail.opponentPoints}
        </div>
        <div>
            {detail.name === "Taken"
                ? isUserTrumpSelector
                    ? detail.opponentPoints
                    : detail.mePoints
                : isUserTrumpSelector
                    ? detail.opponentPoints !== "-" 
                        ? detail.opponentPoints / 10 
                        : detail.opponentPoints
                    : detail.mePoints !== "-"
                        ? detail.mePoints / 10
                        : detail.mePoints}
        </div>
    </div>
))}
<div className="ppbet-results-row ppbet-total">
    <div>Total</div>
    <div>{isUserTrumpSelector ? results.totalMe : results.totalOpponent}</div>
    <div>{isUserTrumpSelector ? results.totalOpponent : results.totalMe}</div>
</div>
                </div>
            </div>
        );
    }

    return null;

    
};

const renderTrumpDecisionPopupButtons = () => {
    if (!game || !game.turn) {
        return null; // Exit early if game or turn data is missing
    }
  
    const isCurrentPlayer = game.turn === user._id;
    const isPassDisabled = game.faceUpCardCopy?.value === 'J' && game.dealer === user._id; // Disable the pass button if the second player cannot pass on the Jack.

    if (showTrumpDecisionPopup && isCurrentPlayer) {
        return (
            <div className="PlayerTrumpDecision-popUp">
                <button className="take-btn" onClick={() => handleTrumpDecision(true)}>Take</button>
                <button
    className="pass-btn"
    onClick={() => {
        audioManager.playSound('buttonClick'); // Play the sound
        handleTrumpDecision(false); // Handle the action
    }}
    disabled={isPassDisabled}
>
    Pass
</button>
            </div>
        );
    } 

    return null;
};



const renderTrumpDecisionPopupDialogForOpponentSection = () =>{
    if (!game || !game.turn) {
        // If the game object is null or game.turn is not defined, return null to avoid errors
        return null;
    }
    const isCurrentPlayer = game.turn === user._id; // Define isCurrentPlayer at the top
    const opponentId = game.userIds.find(id => id !== user._id);
    

    if (showTrumpDecisionPopup && isCurrentPlayer) {
        return (
            <>
                {/* Popup near the player's own label with "Take?" */}
                <div className="opponent-popup"> {/* Use CSS to position */}
                    <div className="dialog-popup-inner opponent">
                    <h3>{game.playerActions[opponentId] === 'pass' ? 'Pass' : 'Taking?'}</h3>
                    </div>
                </div>
            </>
        );
    } 

}

const renderTrumpDecisionPopupDialogForPlayer = () =>{

    if (!game || !game.turn) {
        // If the game object is null or game.turn is not defined, return null to avoid errors
        return null;
    }

    const isCurrentPlayer = game.turn === user._id; // Define isCurrentPlayer at the top
    
    

    if (showTrumpDecisionPopup && !isCurrentPlayer) {
        return (
            <>
                {/* Popup near the player's own label with "Take?" */}
                <div className="player-popup-inOtherScreen"> {/* Use CSS to position */}
                    <div className="dialog-popup-inner player">
                    <h3>{game.playerActions[user._id] === 'pass' ? 'Pass' : 'Taking?'}</h3>
                    </div>
                </div>
            </>
        );
    }

}

const renderTrumpSelectionPopupButtons = () => {
    if (!game || !game.turn) {
        return null;
    }
    const faceUpCardSuit = game.faceUpCardCopy?.suit;
    const isCurrentPlayer = game.turn === user._id;

    if (showTrumpSelectionPopup && isCurrentPlayer) {
        return (
            <div className="PlayerTrumpSelection-popUp">
                <div className="button-container">
                    {faceUpCardSuit !== 'hearts' && (
                        <button className="hearts" onClick={() => handleTrumpSelection('hearts')}>
                            <img src="/icons/hearts.svg" alt="Hearts Icon" />
                        </button>
                    )}
                    {faceUpCardSuit !== 'diamonds' && (
                        <button className="diamonds" onClick={() => handleTrumpSelection('diamonds')}>
                            <img src="/icons/diamonds.svg" alt="Diamonds Icon" />
                        </button>
                    )}
                    {faceUpCardSuit !== 'clubs' && (
                        <button className="clubs" onClick={() => handleTrumpSelection('clubs')}>
                            <img src="/icons/clubs.svg" alt="Clubs Icon" />
                        </button>
                    )}
                    {faceUpCardSuit !== 'spades' && (
                        <button className="spades" onClick={() => handleTrumpSelection('spades')}>
                            <img src="/icons/spades.svg" alt="Spades Icon" />
                        </button>
                    )}
                    {faceUpCardSuit !== 'anxoz' && (
                        <button className="anxoz" onClick={() => handleTrumpSelection('anxoz')}>
                            <img src="/icons/anxoz.svg" alt="Anxoz Icon" />
                        </button>
                    )}
                </div>
                <button
    className="btn"
    onClick={() => {
        audioManager.playSound('buttonClick'); // Play the sound
        handleTrumpSelection('pass'); // Handle the action
    }}
>
    Pass
</button>

            </div>
        );
    }
};



const renderTrumpSelectionPopupDialogForPlayer = () => {
    if (!game || !game.turn) {
        // If the game object is null or game.turn is not defined, return null to avoid errors
        return null;
    }

    const isCurrentPlayer = game.turn === user._id;
    

    if (showTrumpSelectionPopup && !isCurrentPlayer) {

    return (
        <div className="player-popup-inOtherScreen"> {/* Use CSS to position */}
                <div className="dialog-popup-inner player">
                <h3>{game.playerActions[user._id] === 'pass' ? 'Pass' : 'Any other?'}</h3>
            </div>
        </div>
    );
    }
};

const renderTrumpSelectionPopupDialogForOpponentSection = () => {
    if (!game || !game.turn) {
        // If the game object is null or game.turn is not defined, return null to avoid errors
        return null;
    }
    
    const isCurrentPlayer = game.turn === user._id; // Define isCurrentPlayer at the top

    if (showTrumpSelectionPopup && isCurrentPlayer) {

    return (
        <div className="opponent-popup"> {/* Use CSS to position */}
                <div className="dialog-popup-inner opponent">
                <h3>Pass</h3>
            </div>
        </div>
    );
    }
};

const renderTrumpFinalSelectionPopupButtons = () => {
    if (!game || !game.turn) {
        return null; // Prevent errors if game or turn is not defined
    }

    const isCurrentPlayer = game.turn === user._id; // Determine if the current player is the user

    if (showTrumpFinalDecisionPopup && isCurrentPlayer) {
        return (
            
            <div className="trump-final-decision-popup">
            {/* Display the selected suit icon */}
            {game.trumpSuit && (
                <div className="selected-suit-icon">
                    <img src={`/icons/${game.trumpSuit}.svg`} alt={`${game.trumpSuit} icon`} />
                </div>
            )}
        
            <div className="button-container">
                <button className="btn" onClick={() => handleFinalTrumpDecision(true)}>With Card</button>
                <button className="btn" onClick={() => handleFinalTrumpDecision(false)}>Without</button>
            </div>
        </div>
        

        );
    }
};


const renderTrumpFinalSelectionPopupDialogForPlayer = () => {
    if (!game || !game.turn) {
        // If the game object is null or game.turn is not defined, return null to avoid errors
        return null;
    }

    const isCurrentPlayer = game.turn === user._id;
    

    if (showTrumpFinalDecisionPopup && !isCurrentPlayer) {

    return (
        <div className="player-popup-inOtherScreen"> {/* Use CSS to position */}
                <div className="dialog-popup-inner player">
                <h3>{game.playerActions[user._id] === 'pass' ? 'Pass' : 'Any other?'}</h3>
            </div>
        </div>
    );
    }
};

const renderTrumpFinalSelectionPopupDialogForOpponentSection = () => {
    if (!game || !game.turn) {
        // If the game object is null or game.turn is not defined, return null to avoid errors
        return null;
    }
    
    const isCurrentPlayer = game.turn === user._id; // Define isCurrentPlayer at the top

    if (showTrumpFinalDecisionPopup && isCurrentPlayer) {

    return (
        <div className="opponent-popup"> {/* Use CSS to position */}
                <div className="dialog-popup-inner opponent">
                <h3>Pass</h3>
            </div>
        </div>
    );
    }
};

const renderAnnounceCancelButtons = () => {
    if (!game || !game.turn) {
        return null; // Exit early if game or turn data is missing
    }
    
    const playerCombinations = game.combinations?.[user._id];
    const isCurrentPlayer = game.turn === user._id;
    const playerHand = game.hands?.[user._id] || null;

    // Only show buttons if it's the correct phase and it's the player's turn
    if ((game.phase === 'in-progress' || game.phase === 'card-played') && showAnnounceCancelPopup && playerHand?.length === 8 && isCurrentPlayer && hasValidCombinations(playerCombinations)) {
        return (
            <div className="PlayerAnnounceCancel-popup">
                <div className="button-container">
                    <button className="announce-btn" onClick={() => handleAnnounceCancel('announce')}>Announce</button>
                    <button className="cancel-btn" onClick={() => handleAnnounceCancel('cancel')}>Cancel</button>
                </div>
            </div>
        );
    }

    return null; // Don't render buttons if the condition isn't met
};



const renderCombinationPopupDialogForPlayer = (userId) => {

    // Check if the popup should be shown for this user
    if (combinationPopupFlags[userId]) {
        const playerCombinations = game.combinations[userId];

       

        const hasValidCombinations = (
            playerCombinations.tierce.length > 0 ||
            playerCombinations.fifty.length > 0 ||
            playerCombinations.hundred.length > 0 ||
            playerCombinations.fourX.length > 0
        );
        
        if (!game || !hasValidCombinations || playerCombinationDecision.current === 'cancel') {
            // Return null if the player has no valid combinations or if the player has selected "cancel"
            return null;
        }

        // Generate the popup text based on the player's combinations
        const combinationText = generateCombinationText(playerCombinations); // Custom function to format the text

        return (
            <div className="player-popupComb">
                <div className="dialog-popup-inner player">
                    <h3>{combinationText}</h3>
                </div>
            </div>
        );
    }

    return null; // No popup if the flag is false
};

const generateCombinationText = (playerCombinations) => {
    if (!playerCombinations) return "";

    let text = "";
    if (playerCombinations.tierce.length) text += playerCombinations.tierce.length > 1 ? `Tierce (${playerCombinations.tierce.length}x) ` : "Tierce ";
    if (playerCombinations.fifty.length) text += playerCombinations.fifty.length > 1 ? `50 (${playerCombinations.fifty.length}x) ` : "50 ";
    if (playerCombinations.hundred.length) text += playerCombinations.hundred.length > 1 ? `100 (${playerCombinations.hundred.length}x) ` : "100 ";
    if (playerCombinations.fourX.length) text += playerCombinations.fourX.length > 1 ? `4x (${playerCombinations.fourX.length}x) ` : "4x ";

    return text ;  // Return the generated text
};


const renderCombinationPopupDialogForOpponent = (opponentId) => {


    const opponentCombinations = game.combinations[opponentId];

    const hasValidCombinations = (
        opponentCombinations.tierce.length > 0 ||
        opponentCombinations.fifty.length > 0 ||
        opponentCombinations.hundred.length > 0 ||
        opponentCombinations.fourX.length > 0
    );

    if (!game || !hasValidCombinations || game.skipCombinationDecisions[opponentId]===true) {
        return null;
    }

    const combinationText = generateCombinationText(opponentCombinations);

    return (
        <div className="opponent-popup">
            <div className="dialog-popup-inner opponent">
                <h3>{combinationText}</h3>
            </div>
        </div>
    );
};




const handleAnnounceCancel = (choice) => {
    
    playerCombinationDecision.current = choice;
    setShowAnnounceCancelPopup(false);

};

const hasValidCombinations = (combinations) => {
    if (!combinations) return false;
    
    // Check if the player has any non-empty combination arrays or a true "blot" flag
    const { tierce, fifty, fourX, hundred } = combinations;
    
    return (
        (tierce && tierce.length > 0) ||
        (fifty && fifty.length > 0) ||
        (fourX && fourX.length > 0) ||
        (hundred && hundred.length > 0)
    );
};




const renderOpponentCombination = () => {
    if (!showCombination.opponent) return null;
    const userId = user._id;
    const winnerCombinations = game.winnerCombinations;

  
    // Show opponent combination only if player's hand length is 7
    if (game?.moveCounter > 5) {
        return null;
    }

    // Extract the winnerId from winnerCombinations (it's the key in this object)
    const winnerId = Object.keys(winnerCombinations)[0]; // Assuming only one winner for simplicity

    // If the user is the winner, we don't need to display the opponent combination
    if (userId === winnerId) {
        return null;
    }

    // Get the winner's combination (which will be the opponent's combination for the current player)
    const opponentCombination = winnerCombinations[winnerId];

    if (!opponentCombination) {
        // No combination to show, return nothing
        return null;
    }

    // Extract combination cards from the winner (opponent's) combination
    const combinationCards = ['fifty', 'tierce', 'hundred', 'fourX'].flatMap(type =>
        opponentCombination[type]?.flatMap(combo => combo.cards) || []
    );

    return (
        <div className="opponent-combination">
            {combinationCards.map((card, index) => (
                <div
                    key={card._id}
                    className="combination-card"
                    style={{
                        '--card-rotation': '0deg', // Keep combination cards straight or add rotation if needed
                        '--card-translateY': '0px' // Keep them in a neutral position
                    }}
                >
                    <img
                        src={`/cards/${card.value}_of_${card.suit}.png`}
                        alt={`${card.value} of ${card.suit}`}
                    />
                </div>
            ))}
        </div>
    );
};






// Function to render the initial deck of cards 
const renderInitialDeck = () => {
    if (!game || !game.turn) {
        return null;
    }
    if (initialDeck.length === 0) return null; // Don't render if no cards are in the deck

    return (
        <div className="initial-deck">
            {initialDeck.map((card, index) => (
                <div
                    key={index}
                    className="initial-deck-card"
                    style={{
                        position: 'absolute',
                        zIndex: card.zIndex,
                        transform: `translateY(${(initialDeck.length - index - 1) * -0.5}px) translateX(${(initialDeck.length - index - 1) * -0.5}px)`, // Slight offset upward and to the right
                    }}
                >
                    <img
                        src="/cards/card_back.png"
                        alt="Card Back"
                        style={{ width: '70px', height: '110px' }}
                    />
                </div>
            ))}
        </div>
    );
};

const renderDealerSign = (playerId) => {
    if (game.dealer === playerId) {
        return (
            <div className="dealer-sign">
                <img src="/icons/dealer-icon.svg" alt="Dealer Icon" />
            </div>
        );
    }
    return null;
};




 // Function definitions must be outside the useEffect
 const fetchGameState = useCallback(async () => {
    try {
        const response = await apiRequest(`${API_BASE_URL}/api/current-game` , {
            method: 'GET',
            credentials: 'include', // Ensure cookies are sent with the request
        });

        const data = await response.json();
        console.log('Fetched game state:', data);



        if (response.ok && data.game) {
            const game = data.game;

            // Check if the game status is 'completed'
            if (game.status === 'completed') {
                setInitialDeck([]);
                initialDeckCardsRef.current = []; // Clear the ref as well
                console.log('Game is completed, redirecting to home.');
                navigate('/game');
                return; // Exit the function early since the game is completed
            }

            // Handle the card played phase as an initial load
            handleCardPlayedPhase(game, true); // Passing true as this is the initial load after a refresh
            handleGameUpdate(data); // Handle other game updates or state logic
        } else {
            console.log('No game in progress');
            navigate('/game');
        }
    } catch (error) {
        console.error('Error fetching game state:', error);
    }
}, [navigate]);

// Visibility change handler that calls fetchGameState on visibility change
useEffect(() => {
    const handleVisibilityChange = () => {
        if (!document.hidden) {
            console.log('Tab is active, fetching the game state');
            fetchGameState();
        }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
}, [fetchGameState]); // Dependency on fetchGameState to ensure the latest version is used

// Initial effect to fetch game state when the component mounts
useEffect(() => {
    fetchGameState(); // Fetch game state on component mount
}, [fetchGameState]); // Ensure we use the latest fetchGameState




return (
    <div className="blot-page">
       
        {renderGamePoints()} {/* Render the game points in the top right corner */}
        {renderLastPlayedHand()} {/* Render the last played hand cards */}
        {game && game.hands && (
            <>
          
            <div className={`opponent-section ${game.turn === opponent?._id && !['initial-deal', 'deal-initial-cards','deal-remaining-cards','face-up-card-taken', 'face-up-card-skeeped', 'round-canceled', 'round-completed', 'game-completed', 'game-canceled','trick-in-progress'].includes(game.phase) ? 'highlighted waiting-for-move' : ''}`} data-player-id={opponent?._id}>
         
            {renderDealerSign(opponent?._id)}
               
                <div className="player-label opponent">
                    <div className="player-icon">
                        <img src="/icons/avatar1.svg" alt="Player Avatar" />
                        <svg className="timer-circleX">
                          <defs>
                            <linearGradient id="gradientStroke" x1="50%" y1="0%" x2="50%" y2="100%">
                              <stop offset="0%" style={{ stopColor: '#199519', stopOpacity: 1 }} />
                              <stop offset="100%" style={{ stopColor: '#19E127', stopOpacity: 1 }} />
                            </linearGradient>
                          </defs>
                          <circle className="base" cx="50%" cy="50%" r="45%" />
                          <circle className="timer" cx="50%" cy="50%" r="45%" stroke="url(#gradientStroke)" />
                        </svg>
                    </div>

                    {game.trumpSuit && game.trumpSuitSelectedBy === opponent?._id && (
                        <div className="trump-icon">
                            <img src={`/icons/${game.trumpSuit}.svg`} alt="Trump Icon" />
                        </div>
                    )}

                    <div className="player-info-text">
                        <div className="name-and-flag">
                            <span className="player-name">
                                {opponent?.nickname}
                            </span>
                            {opponent?.country && (
                                <img
                                    src={`/flags/${opponent.country.toLowerCase()}.svg`}
                                    alt={`${opponent.country} flag`}
                                    className="country-flagL"
                                />
                            )}
                        </div>
                        <span className="player-score">
                            {opponent?.score || 0}
                        </span>
                        <span className="round-points">
                            {game.roundPoints && game.roundPoints[opponent?._id] ? game.roundPoints[opponent._id] : 0}
                        </span>
                    </div>
                </div>

                {renderOpponentHand()} {/* Use the renderOpponentHand function here */}
            </div>

            {game && game.hands && (
                <>
                    <div className="playarea">
                        {localPlayedCards.current.map((playedCardObj) => (
                            <div 
                                key={playedCardObj._id} 
                                className={`card in-play ${playedCardObj.positionClass}`} 
                                data-id={playedCardObj._id} 
                                data-player-id={playedCardObj.player}
                            >
                                <img
                                    src={`/cards/${playedCardObj.value}_of_${playedCardObj.suit}.png`}
                                    alt={`${playedCardObj.value} of ${playedCardObj.suit}`}
                                />
                            </div>
                        ))}
                    </div>

                    <div className={`player-section ${game.turn === user._id && !['initial-deal', 'deal-initial-cards','deal-remaining-cards','face-up-card-taken', 'face-up-card-skeeped', 'round-canceled', 'round-completed', 'game-completed', 'game-canceled','trick-in-progress'].includes(game.phase) ? 'highlighted waiting-for-move' : ''}`} data-player-id={user._id}>
                        
                    {renderDealerSign(user._id)}

                        <div className="player-label player">
                            <div className="player-icon">
                                <img src="/icons/avatar1.svg" alt="Player Avatar" />
                                <svg className="timer-circleX">
                                  <defs>
                                    <linearGradient id="gradientStroke" x1="50%" y1="0%" x2="50%" y2="100%">
                                      <stop offset="0%" style={{ stopColor: '#199519', stopOpacity: 1 }} />
                                      <stop offset="100%" style={{ stopColor: '#19E127', stopOpacity: 1 }} />
                                    </linearGradient>
                                  </defs>
                                  <circle className="base" cx="50%" cy="50%" r="45%" />
                                  <circle className="timer" cx="50%" cy="50%" r="45%" stroke="url(#gradientStroke)" />
                                </svg>
                            </div>

                            {game.trumpSuit && game.trumpSuitSelectedBy === user._id && (
                                <div className="trump-icon">
                                    <img src={`/icons/${game.trumpSuit}.svg`} alt="Trump Icon" />
                                </div>
                            )}
                            
                            <div className="player-info-text">
                                <div className="name-and-flag">
                                    <span className="player-name">
                                        {user.nickname}
                                    </span>
                                    {user.country && (
                                        <img
                                            src={`/flags/${user.country.toLowerCase()}.svg`}
                                            alt={`${user.country} flag`}
                                            className="country-flagL"
                                        />
                                    )}
                                </div>
                                <span className="player-score">
                                    {user.score || 0}
                                </span>
                                <span className="round-points">
                                    {game.roundPoints && game.roundPoints[user._id] ? game.roundPoints[user._id] : 0}
                                </span>
                            </div>
                        </div>

                        {renderPlayerHand()} {/* Use the renderPlayerHand function here */}
                    </div>

                    <div className="deck-container">
                        {/* Render the deck */}
                        {(game.phase === 'initial-deal' || 
                        game.phase === 'deal-initial-cards' || 
                        game.phase === 'face-up-card-taken' || 
                        game.phase === 'in-progress' ||
                        game.phase === 'face-up-card-skeeped' || 
                        game.phase === 'deal-remaining-cards' || 
                        game.phase === 'trump-decision' || 
                        game.phase === 'trump-selection' || 
                        game.phase === 'trump-final-selection') && renderInitialDeck()}

                        {renderFaceUpCard()}
                    </div>
                </>
            )}
            </>
        )}
        {renderPopup()}
    </div>
);

};

export default BlotPage;


