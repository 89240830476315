import React, { useEffect, useState, useContext, useRef } from 'react';
import { AuthContext } from '../context/AuthContext';
import './Profile.css';
import { useNavigate } from 'react-router-dom';
import { apiRequest } from '../api';
import API_BASE_URL from '../apiConfig';

const Profile = () => {
    const navigate = useNavigate();
    const { user, updateUserDetails } = useContext(AuthContext);
    const [nickname, setNickname] = useState(user ? user.nickname || user.username : '');
    const [country, setCountry] = useState(user ? user.country || '' : '');
    const [wins, setWins] = useState(0);
    const [losses, setLosses] = useState(0);
    const [totalGames, setTotalGames] = useState(0);
    const [isSaving, setIsSaving] = useState(false); 
    const [updateSuccessPopup, setUpdateSuccessPopup] = useState({ isVisible: false, message: '' }); 
    const dropdownRef = useRef(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState(''); 

    const filteredCountries = countryList.filter(country => 
        country.name.toLowerCase().includes(searchTerm.toLowerCase()) 
    );

    const toggleDropdown = () => {
        if (isDropdownOpen) {
            setSearchTerm(''); 
        }
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleCountrySelect = (selectedCountryCode) => {
        if (selectedCountryCode === '') {
            setCountry(''); 
        } else {
            setCountry(selectedCountryCode); 
        }
        setIsDropdownOpen(false); 
    };

     
     const handleInputClick = (e) => {
        e.stopPropagation();
    };

    useEffect(() => {
        const fetchStatistics = async () => {
            try {
                const response = await apiRequest(`${API_BASE_URL}/api/statistics`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                const data = await response.json();
                if (response.ok) {
                    setWins(data.wins);
                    setLosses(data.losses);
                    setTotalGames(data.totalGames);
                } else {
                    console.error('Failed to fetch statistics:', data.message);
                }
            } catch (err) {
                console.error('Error fetching statistics:', err);
            }
        };

        if (user) {
            fetchStatistics();
        }
    }, [user]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false); 
                setSearchTerm(''); 
            }
        };

       
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const escapeHTML = (input) => {
        const map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;',
        };
        return input.replace(/[&<>"']/g, (char) => map[char]);
    };

    const handleSaveProfile = async () => {
        setIsSaving(true);
        
        try {
            const sanitizedNickname = escapeHTML(nickname);
            const payload = {
                nickname: sanitizedNickname,
                country: country === '' ? null : country
            };
            const response = await apiRequest(`${API_BASE_URL}/api/update-profile`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });

            const data = await response.json();
            if (response.ok) {
                updateUserDetails(); 
                setUpdateSuccessPopup({
                    isVisible: true,
                    message: 'Profile updated successfully!',
                });
            } else {
                console.error('Failed to update profile:', data.message);
            }
        } catch (err) {
            console.error('Error updating profile:', err);
        } finally {
            setIsSaving(false);
        }
    };

    useEffect(() => {
        const handlePopState = (event) => {
            event.preventDefault();
            navigate('/game');
        };

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate]);

    

    return (
        <div className="profile-container">
            <h2 className="profile-title">Profile</h2>

            <div className="profile-section">
                <label className="profile-label">Nickname:</label>
                <div className="nickname-edit">
                    <input
                        type="text"
                        value={nickname}
                        onChange={(e) => setNickname(e.target.value)}
                        className="profile-input"
                    />
                    <img src="/icons/edit-icon.svg" alt="Edit" className="edit-icon" />
                </div>
            </div>

            <div className="profile-section-country-select">
            <label className="profile-label-country-select">Country:</label>
            <div className="custom-dropdown-country-select" ref={dropdownRef} onClick={toggleDropdown}>
                <div className="selected-country-country-select">
                    {country && country !== '' ? (
                        <>
                            <img
                                src={`/flags/${country.toLowerCase()}.svg`}
                                alt={`${country} flag`}
                                className="flag-icon-country-select"
                            />
                            {countryList.find((c) => c.code === country)?.name}
                        </>
                    ) : (
                        'Select Country'
                    )}
                </div>
                {isDropdownOpen && (
                    <div className="dropdown-list-country-select">
                       
                        <input
                            type="text"
                            placeholder="Search country..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onClick={handleInputClick} 
                            className="search-input-country-select"
                        />
                        
                        {filteredCountries.length > 0 ? (
                            filteredCountries.map((country) => (
                                <div
                                    key={country.code}
                                    className="dropdown-item-country-select"
                                    onClick={() => handleCountrySelect(country.code)}
                                >
                                    {country.code !== '' && (
                                        <img
                                            src={`/flags/${country.code.toLowerCase()}.svg`}
                                            alt={`${country.name} flag`}
                                            className="flag-icon-country-select"
                                        />
                                    )}
                                    {country.name}
                                </div>
                            ))
                        ) : (
                            <div className="no-results-country-select">No results found</div>
                        )}
                    </div>
                )}
            </div>
        </div>


            <button
                className={`profile-save-button ${isSaving ? 'profile-button-loading' : ''}`}
                onClick={handleSaveProfile}
                disabled={isSaving}
            >
                {isSaving ? (
                    <>
                        <span className="spinner"></span> Saving...
                    </>
                ) : "Save Profile"}
            </button>

            <div className="profile-statistics">
                <h3>Statistics</h3>
                <div className="profile-circle-container">
                    <div className="profile-circle">
                    <svg width="80" height="80">
    <circle cx="45" cy="45" r="40" stroke="black" strokeWidth="8" fill="none" />
    <circle
        cx="45"
        cy="45"
        r="40"
        stroke="#13C513"
        strokeWidth="8"
        fill="none"
        strokeDasharray="251 251" 
        style={{ '--stroke-dashoffset': 251 - (wins / totalGames) * 251 }}
        strokeDashoffset={totalGames > 0 ? 251 - (wins / totalGames) * 251 : 0}   
        transform="rotate(-90 45 45)" 
        className="progress-circleX"
    />
</svg>

                       
                        <span className="stat-number">{wins}</span>
    <p className="stat-label">Wins</p>
    <p className="stat-percentage">
  {totalGames > 0 ? Math.round((wins / totalGames) * 100) : 0}%
</p>

                    </div>

                    <div className="profile-circle">
                    <svg width="90" height="90">
    <circle cx="45" cy="45" r="40" stroke="Black" strokeWidth="8" fill="none" />
    <circle
        cx="45"
        cy="45"
        r="40"
        stroke="#ff4d4d"
        strokeWidth="8"
        fill="none"
        strokeDasharray="251 251" /* Full circle */
        style={{ '--stroke-dashoffset': 251 - (losses / totalGames) * 251 }} 
        strokeDashoffset={totalGames > 0 ? 251 - (losses / totalGames) * 251 : 0}  
        transform="rotate(-90 45 45)" 
        className="progress-circleX"
    />
</svg>


                        <span className="stat-number">{losses}</span>
    <p className="stat-label">Losses</p>
    <p className="stat-percentage">
  {totalGames > 0 ? Math.round((losses / totalGames) * 100) : 0}%
</p>

                    </div>

                    <div className="profile-circle">
                    <svg width="90" height="90">
    <circle cx="45" cy="45" r="40" stroke="Black" strokeWidth="8" fill="none" />
    <circle
        cx="45"
        cy="45"
        r="40"
        stroke="#9E9E9E"
        strokeWidth="8"
        fill="none"
        strokeDasharray="251 251" 
        strokeDashoffset="0" 
        transform="rotate(-90 45 45)" 
        className="progress-circleX"
    />
</svg>




                        <span className="stat-number">{totalGames}</span>
                        <p className="stat-label">Games</p>
                        <p className="stat-percentage">Total Played</p>
                    </div>
                </div>
            </div>

            
            {updateSuccessPopup.isVisible && (
                <div
                    className="profile-update-popup-overlay"
                    onClick={(e) => {
                        if (!e.target.closest('.profile-update-popup')) {
                            setUpdateSuccessPopup({ isVisible: false });
                        }
                    }}
                >
                    <div className="profile-update-popup">
                        <h3 className="popup-title">Success</h3>
                        <p className="popup-message">{updateSuccessPopup.message}</p>
                        <button
                            className="profile-popup-close-button"
                            onClick={() => setUpdateSuccessPopup({ isVisible: false })}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}

           
        </div>
    );
};



     

const countryList = [
    { code: '', name: 'Not set' }, 
    { code: 'AF', name: 'Afghanistan' },
    { code: 'AL', name: 'Albania' },
    { code: 'AM', name: 'Armenia' },          
    { code: 'AR', name: 'Argentina' },
    { code: 'AT', name: 'Austria' },
    { code: 'AU', name: 'Australia' },
    { code: 'AZ', name: 'Azerbaijan' },       
    { code: 'BD', name: 'Bangladesh' },
    { code: 'BE', name: 'Belgium' },
    { code: 'BG', name: 'Bulgaria' },          
    { code: 'BR', name: 'Brazil' },           
    { code: 'BY', name: 'Belarus' },
    { code: 'CA', name: 'Canada' },
    { code: 'CH', name: 'Switzerland' },
    { code: 'CL', name: 'Chile' },
    { code: 'CN', name: 'China' },             
    { code: 'CO', name: 'Colombia' },
    { code: 'CU', name: 'Cuba' },
    { code: 'CY', name: 'Cyprus' },            
    { code: 'CZ', name: 'Czech Republic' },
    { code: 'DE', name: 'Germany' },          
    { code: 'DK', name: 'Denmark' },
    { code: 'DZ', name: 'Algeria' },
    { code: 'EG', name: 'Egypt' },            
    { code: 'ES', name: 'Spain' },
    { code: 'ET', name: 'Ethiopia' },
    { code: 'FI', name: 'Finland' },
    { code: 'FR', name: 'France' },           
    { code: 'GB', name: 'United Kingdom' },   
    { code: 'GE', name: 'Georgia' },           
    { code: 'GR', name: 'Greece' },            
    { code: 'HR', name: 'Croatia' },
    { code: 'HU', name: 'Hungary' },
    { code: 'ID', name: 'Indonesia' },        
    { code: 'IN', name: 'India' },             
    { code: 'IR', name: 'Iran' },              
    { code: 'IQ', name: 'Iraq' },              
    { code: 'IS', name: 'Iceland' },
    { code: 'IT', name: 'Italy' },
    { code: 'JP', name: 'Japan' },             
    { code: 'JO', name: 'Jordan' },            
    { code: 'KE', name: 'Kenya' },
    { code: 'KG', name: 'Kyrgyzstan' },        
    { code: 'KH', name: 'Cambodia' },
    { code: 'KR', name: 'South Korea' },      
    { code: 'KW', name: 'Kuwait' },            
    { code: 'KZ', name: 'Kazakhstan' },
    { code: 'LB', name: 'Lebanon' },          
    { code: 'LK', name: 'Sri Lanka' },
    { code: 'LT', name: 'Lithuania' },
    { code: 'LU', name: 'Luxembourg' },
    { code: 'LV', name: 'Latvia' },
    { code: 'MA', name: 'Morocco' },          
    { code: 'MD', name: 'Moldova' },           
    { code: 'ME', name: 'Montenegro' },
    { code: 'MK', name: 'North Macedonia' },
    { code: 'MN', name: 'Mongolia' },
    { code: 'MX', name: 'Mexico' },            
    { code: 'MY', name: 'Malaysia' },
    { code: 'NG', name: 'Nigeria' },           
    { code: 'NL', name: 'Netherlands' },
    { code: 'NO', name: 'Norway' },
    { code: 'NZ', name: 'New Zealand' },
    { code: 'OM', name: 'Oman' },             
    { code: 'PE', name: 'Peru' },
    { code: 'PH', name: 'Philippines' },      
    { code: 'PK', name: 'Pakistan' },        
    { code: 'PL', name: 'Poland' },
    { code: 'PT', name: 'Portugal' },
    { code: 'QA', name: 'Qatar' },             
    { code: 'RO', name: 'Romania' },          
    { code: 'RS', name: 'Serbia' },           
    { code: 'RU', name: 'Russia' },           
    { code: 'SA', name: 'Saudi Arabia' },     
    { code: 'SD', name: 'Sudan' },
    { code: 'SE', name: 'Sweden' },
    { code: 'SG', name: 'Singapore' },
    { code: 'SI', name: 'Slovenia' },
    { code: 'SK', name: 'Slovakia' },
    { code: 'SY', name: 'Syria' },             
    { code: 'TH', name: 'Thailand' },          
    { code: 'TN', name: 'Tunisia' },           
    { code: 'TR', name: 'Turkey' },           
    { code: 'TZ', name: 'Tanzania' },
    { code: 'UA', name: 'Ukraine' },           
    { code: 'UG', name: 'Uganda' },
    { code: 'US', name: 'United States' },     
    { code: 'UY', name: 'Uruguay' },
    { code: 'UZ', name: 'Uzbekistan' },        
    { code: 'VE', name: 'Venezuela' },
    { code: 'VN', name: 'Vietnam' },           
    { code: 'YE', name: 'Yemen' },            
    { code: 'ZA', name: 'South Africa' },     
    { code: 'ZW', name: 'Zimbabwe' }
];


export default Profile;
