import React, { useState, useEffect, useContext } from 'react';
import { apiRequest } from '../api';
import './PlayedGameHistory.css';
import { AuthContext } from '../context/AuthContext';
import API_BASE_URL from '../apiConfig';

const PlayedGameHistory = () => {
    const { user } = useContext(AuthContext);
    const [games, setGames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedGame, setSelectedGame] = useState(null); // To store selected game details
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const scrollableContent = document.getElementById('scrollableContent');

        if (selectedGame) {
            // Lock scroll and calculate current scroll position
            scrollableContent.style.overflow = 'hidden';
            const currentScroll = scrollableContent.scrollTop;
            setScrollPosition(currentScroll); // Store scroll position
        } else {
            // Unlock scroll
            scrollableContent.style.overflow = 'auto';
        }

        return () => {
            scrollableContent.style.overflow = 'auto';
        };
    }, [selectedGame]);
    


    useEffect(() => {
        const fetchGames = async () => {
            try {
                setLoading(true);
                const response = await apiRequest(`${API_BASE_URL}/api/played-games-history/${user._id}` , {
                    method: 'GET',
                    credentials: 'include',
                });
    
                if (response.ok) {
                    const data = await response.json();
                    setGames(data.games.reverse());  
                } else {
                    setError('Failed to fetch game history');
                }
            } catch (err) {
                setError('Error fetching game history');
            } finally {
                setLoading(false);
            }
        };
    
        if (user && user._id) {
            fetchGames();
        }
    }, [user]);

    


    const handleGameClick = (game) => {
        setSelectedGame(game);
        
    };

    const handleClosePopup = () => {
        setSelectedGame(null);
    };

    const groupedGames = games.reduce((acc, game) => {
        const date = new Date(game.date).toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'long',
        });

        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(game);
        return acc;
    }, {});

    if (loading) {
        return <div className="played-games-history-container-loading-message">Loading...</div>;
    }

    if (error) return <div className="played-games-history-container-error-message">{error}</div>;

    return (
        <div className="played-games-history-container">
            <h2 className="played-games-history-page-titleO">Played Games</h2>
    
            <div className="played-games-history-fullList">
                {Object.keys(groupedGames).length > 0 ? (
                    Object.keys(groupedGames).map((date) => {
                        const isValidDate = date !== "Invalid Date" && date !== "undefined"; 
                        return (
                            <div key={isValidDate ? date : `fallback-${Math.random()}`} className="played-games-history-group">
                                <h3 className="played-games-history-date">{isValidDate ? date : "Unknown Date"}</h3>
                                {groupedGames[date].map((game) => (
                                    <div
                                        key={game._id} 
                                        className="played-games-history-item"
                                        onClick={() => handleGameClick(game)} 
                                    >
                                        <div className="played-games-history-info">
                                            <span className="played-games-history-nameO">{game.gameName}</span>
                                            <span className="played-games-history-bet-result">
                                                <span className="played-games-history-bet">Bet: ${game.bet.toFixed(2)}</span>
                                                <span className={`played-games-history-result ${game.result.toLowerCase()}`}>
                                                    {game.result === 'win' ? 'Win' : 'Lose'}
                                                </span>
                                            </span>
                                        </div>
                                        <img src="/icons/arrow-icon.svg" alt="Arrow Icon" className="played-games-history-arrow" />
                                    </div>
                                ))}
                            </div>
                        );
                    })
                ) : (
                    <div className="played-games-history-empty-message">No games available</div>
                )}
            </div>
    
            {/* Popup for game details */}
            {selectedGame && (
                <div
                    className="played-games-history-popup-overlay"
                    style={{ top: `${scrollPosition}px` }}
                    onClick={handleClosePopup}
                >
                    <div
                        className="played-games-history-popup"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="played-games-history-popup-header">
                            <h3 className="played-games-history-popup-title">Game Details</h3>
                            <button
                                className="played-games-history-close-btn"
                                onClick={handleClosePopup}
                            >
                                &times;
                            </button>
                        </div>
                        <hr className="popup-separator" />
                        <div className="popup-body">
                            <p>
                                <span className="row-label">Game: </span>
                                <span className="row-data">{selectedGame.gameName}</span>
                            </p>
                            <p>
                                <span className="row-label">Bet amount: </span>
                                <span className="row-data">
                                    ${selectedGame.bet.toFixed(2)}
                                </span>
                            </p>
                            <p>
                                <span className="row-label">Opponent: </span>
                                <span className="row-data opponent-name">
                                    {selectedGame.opponentUsername}
                                </span>
                            </p>
                            <p>
                                <span className="row-label">Game score: </span>
                                <span className="row-data game-pointsResult">
                                    {selectedGame.userScore} : {selectedGame.opponentScore}
                                </span>
                            </p>
                            <p>
                                <span className="row-label">Result: </span>
                                <span
                                    className={`row-data status-${selectedGame.result}`}
                                >
                                    {selectedGame.result === 'win' ? 'Win' : 'Lose'}
                                </span>
                            </p>
                            <p>
                                <span className="row-label">Date: </span>
                                <span className="row-data">
                                    {new Date(selectedGame.date).toLocaleString()}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
    
    
};

export default PlayedGameHistory;
