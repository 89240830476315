

class AudioManager {
    constructor() {
        this.sounds = {};
        this.volume = 1;  // Control the global volume here (0.0 to 1.0)
    }

    loadSound(name, path) {
        if (!this.sounds[name]) {
            const audio = new Audio(path);
            audio.volume = this.volume;
            this.sounds[name] = audio;
        }
    }


    playSound(name) {
        const sound = this.sounds[name];
        if (sound) {
        sound.play().catch(error => console.error(`Error playing sound ${name}:`, error));
        } else {
            console.warn(`Sound ${name} not found!`);
        }
    }


    setVolume(volume) {
        this.volume = volume;
        Object.values(this.sounds).forEach(sound => sound.volume = volume);
    }

    preloadSounds(soundPaths) {
        soundPaths.forEach(({ name, path }) => this.loadSound(name, path));
    }
}

const audioManager = new AudioManager();
export default audioManager;
