import React, { createContext, useState, useEffect, useCallback, useMemo } from 'react';
import { apiRequest } from '../api';
import { useNavigate, useLocation } from 'react-router-dom';
import API_BASE_URL from '../apiConfig';
import { io } from 'socket.io-client';

export const AuthContext = createContext();

const SOCKET_URL = `${API_BASE_URL}`;
const socket = io(SOCKET_URL, {
    transports: ['websocket'],
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 500,
    reconnectionDelayMax: 5000,
    timeout: 10000,
    autoConnect: true,
});

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [balance, setBalance] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    
    const login = useCallback((userData) => {
        setUser(userData);
        setBalance(userData.balance);
    }, []);

    const logout = useCallback(() => {
        setUser(null);
        setBalance(0);
        navigate('/login');
        apiRequest(`${API_BASE_URL}/api/logout`, {
            method: 'POST',
            credentials: 'include',
        });
    }, [navigate]);

    const fetchUserDetails = useCallback(async (retries = 3, delay = 1500) => {
        for (let attempt = 0; attempt < retries; attempt++) {
            try {
                const response = await apiRequest(`${API_BASE_URL}/api/protected`, {
                    method: 'GET',
                    credentials: 'include',
                });
                if (response.ok) {
                    const data = await response.json();
                    setUser(data.user);
                    setBalance(data.user.balance);

                    if (socket && socket.connected) {
                        socket.emit('authenticate', { userId: data.user._id });
                    } else {
                        socket.once('connect', () => {
                            console.log("Socket connected, authenticating...");
                            socket.emit('authenticate', { userId: data.user._id });
                        });
                    }
                    return;
                } else if (response.status === 401) {
                    console.warn(`Attempt ${attempt + 1} failed with 401 Unauthorized`);
                    if (attempt < retries - 1) {
                        await new Promise(resolve => setTimeout(resolve, delay));
                    } else {
                        logout();
                    }
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
                break;
            }
        }
    }, [logout]);

    useEffect(() => {
        const publicRoutes = ['/register', '/forgot-password', '/login'];
        const isResetPasswordRoute = location.pathname.startsWith('/reset-password');

        if (!publicRoutes.includes(location.pathname) && !isResetPasswordRoute) {
            fetchUserDetails();
        }
    }, [fetchUserDetails, location.pathname]);

    // Memoize context values to avoid re-renders
    const contextValue = useMemo(() => ({
        user,
        balance,
        setBalance,
        login,
        logout,
        fetchUserDetails,
        socket // Add socket to the context value
    }), [user, balance, login, logout, fetchUserDetails, socket]);

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider };
